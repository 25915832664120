import {
  downloadAuthFile,
  makeDeleteAuthRequest,
  makeGetAuthRequest,
  makePostAuthRequest,
  makePutAuthRequest, processQueryParams
} from './common';

export const generateReport = (data) => makePostAuthRequest('/report/generate', data);

export const fetchReport = (id) => makeGetAuthRequest(`/report/${id}`);

export const fetchReports = (params) => {
  const queryString = processQueryParams(params);
  const path = '/report';
  const url = `${path}?${queryString}`;
  return makeGetAuthRequest(url);
}

export const deleteReport = (id) => makeDeleteAuthRequest(`/report/${id}`);

export const sendReport = (id, data) => makePostAuthRequest(`/report/${id}/send`, data);
export const sendReportToVendor = (reportId, vendorId, data) =>
  makePostAuthRequest(`/report/${reportId}/send/${vendorId}`, data);

export const updateReport = (id, data) => makePutAuthRequest(`/report/${id}`, data);

export const downloadVendorReport = (vendorId, reportId) => downloadAuthFile(`/report/${reportId}/download/${vendorId}`);

export const downloadReport = (reportId) => downloadAuthFile(`/report/${reportId}/download`);
