import { FormControl, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';

export const VendorStatusSelect = ({ value, onChange }) => {
  return (
    <FormControl size={'small'}>
      <Select value={value} onChange={onChange}>
        <MenuItem value={'active'}>Active</MenuItem>
        <MenuItem value={'pending'}>Pending</MenuItem>
        <MenuItem value={'inactive'}>Inactive</MenuItem>
        <MenuItem value={'flagged'}>Flagged</MenuItem>
      </Select>
    </FormControl>
  );
}