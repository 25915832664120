import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput
} from "@mui/material";
import Select from "@mui/material/Select";
import PropTypes from 'prop-types';
import Iconify from "../iconify";

export const MultiSelect = ({ onChange, onClear, onDeleteItem, filter }) => {
  return (
    <FormControl
      key={filter.title}
      sx={{ width: 300 }}
      variant="outlined"
      size={'small'}
    >
      <InputLabel>{filter.title}</InputLabel>
      <Select
        input={
          <OutlinedInput
            label={filter.title}
            endAdornment={
              filter.value.length
                ? <Iconify
                  sx={{ mr: 3, cursor: 'pointer' }}
                  icon={'mdi:close'}
                  width={24}
                  onClick={onClear}
                />
                : null
            }
          />
        }
        multiple
        value={filter.value}
        onChange={onChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value.value}
                label={value.label}
                size={'small'}
                onMouseDown={(event) => { event.stopPropagation() }}
                onDelete={onDeleteItem}
              />
            ))}
          </Box>
        )}
      >
        {filter.values.map((v, idx) => (
          <MenuItem
            key={idx}
            value={v}
          >{v.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onDeleteItem: PropTypes.func,
  filter: PropTypes.object,
}