import {
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchVendors } from '../../requests/vendor';
import Iconify from '../iconify';
import { VENDORS_ROUTE } from '../../router';
import { VendorStatus } from './VendorStatus';
import { createColumnItem, defaultSorting, DataTable, defaultTableFilter } from '../data-table';
import { VendorCodes } from './VendorCodes';

export const VendorsTable = () => {
  const navigate = useNavigate();

  const goToVendor = (id) => {
    navigate(`${VENDORS_ROUTE}/${id}`);
  }

  const tableConfig = [
    createColumnItem({
      title: 'Status',
      value: (item) => <VendorStatus status={item.status} />,
      width: '5%',
    }),
    // createColumnItem({
    //   title: 'ID',
    //   value: item => item.id,
    //   headerAlign: 'center',
    //   valueAlign: 'center',
    //   width: '5%',
    //   sortable: true,
    // }),
    createColumnItem({
      title: 'Codes',
      value: (item) => <VendorCodes codes={item.codes.map((c) => c.name)} />,
      width: '20%',
    }),
    createColumnItem({
      title: 'Name',
      value: item => item.name,
      width: '20%',
      sortable: true,
    }),
    createColumnItem({
      title: 'Business',
      value: item => item.business,
      width: '20%',
      sortable: true,
    }),
    createColumnItem({
      title: 'Email',
      value: item => item.email,
      width: '20%',
    }),
    createColumnItem({
      title: 'Rate',
      value: item => item.rate,
      width: '10%',
    }),
    createColumnItem({
      title: 'Rent',
      value: item => item.rent,
      width: '10%',
    }),
    createColumnItem({
      value: (item) => (
        <IconButton onClick={() => goToVendor(item.id)}>
          <Iconify icon={'mdi:eye'} color={'blue'} />
        </IconButton>
      ),
      valueAlign: 'right',
    }),
  ];

  const tableFilters = [
    defaultTableFilter({
      title: "Show only active",
      name: 'is_active',
      type: 'boolean',
      value: true,
    }),
    defaultTableFilter({
      title: "Name, business, or code",
      name: 'vendor',
      type: 'search',
    }),
  ];

  return (
    <DataTable
      columnsConfig={tableConfig}
      dataCallback={(...args) => fetchVendors(...args)}
      defaultSorting={[defaultSorting('name', 'asc')]}
      defaultFilters={tableFilters}
    />
  );
}