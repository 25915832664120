export const VendorStatus = ({ status }) => {
  const map = {
    active: {
      text: '#008000',
      bg: '#90ee90'
    },
    pending: {
      text: '#ff8c00',
      bg: '#ffd383'
    },
  };

  const bgColor = map[status]?.bg ?? 'lightgray';
  const textColor = map[status]?.text ?? '#000000';

  return (
    <div style={{
      padding: '3px 12px',
      borderRadius: '.5rem',
      fontWeight: 'bold',
      backgroundColor: `${bgColor}`,
      color: `${textColor}`,
      border: `1px solid ${textColor}`,
      cursor: 'default',
      userSelect: 'none',
      display: 'unset',
    }}>
      {status}
    </div>
  );
}