import { useState } from 'react';
import {
  Button,
  Stack,
} from '@mui/material';
import { AppLayout } from '../../layouts/app';
import { pageTitle } from '../../utils/seo';
import Iconify from '../../components/iconify';
import { CreateVendorModal } from '../../components/vendor/CreateVendorModal';
import { useNotifications } from '../../providers';
import { downloadExport } from '../../requests/vendor';
import { VendorsTable } from '../../components/vendor';

const VendorsListPage = () => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const { addNotification } = useNotifications();

  const openCreateModal = () => {
    setCreateModalIsOpen(true);
  }

  const onCloseCreateModal = () => {
    setCreateModalIsOpen(false);
  }

  const onDownloadList = async () => {
    try {
      await downloadExport();
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  const headerRight = <Stack direction={'row'} alignItems={'center'} spacing={3}>
    <Button onClick={onDownloadList}>
      <Iconify icon={'mdi:download'} />
    </Button>
    <Button onClick={openCreateModal}>
      <Iconify icon={'mdi:plus'} />
    </Button>
  </Stack>;

  return (
    <AppLayout
      title={pageTitle('Vendors')}
      pageHeader={'Vendors'}
      pageHeaderRight={headerRight}
    >
      <VendorsTable />
      <CreateVendorModal
        isOpen={createModalIsOpen}
        onClose={onCloseCreateModal}
      />
    </AppLayout>
  );
}

export { VendorsListPage };