import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { authService } from '../services';
import { fetchMe } from '../requests/auth';
import { useAuth } from '../providers/authProvider';
import { useRequest } from '../hooks/useRequest';

export const AuthGuard = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const accessToken = authService.getAccessToken();

  const fetchMePromise = useRequest(
    () => fetchMe(),
  );

  useEffect(() => {
    if (!accessToken) {
      authService.clear();
      navigate('/login', { replace: true });
      return;
    }
    if (!auth.user) {
      fetchMePromise()
        .then(data => auth.setUser(data));
    }
  }, []);

  if (!accessToken) {
    return null;
  }

  return (
    <>{children}</>
  );
}