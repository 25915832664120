import {
  Divider,
  Grid,
  Tabs,
  Tab,
  TextField,
  Autocomplete, Stack, CircularProgress
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { AppLayout } from '../../layouts/app';
import { useNotifications } from '../../providers';
import {useLoading, useRequest} from '../../hooks';
import {
  deleteReport,
  downloadReport,
  fetchReport,
  sendReport,
  sendReportToVendor,
  updateReport
} from '../../requests/report';
import { dateString, formatDate } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import { REPORTS_ROUTE } from '../../router';
import { ReportVendorItem, ReportHeader, ReportMiscTable } from '../../components/report';
import { EditorComponent } from '../../components/editor';

const Header = ({ value }) => <div><b>{value}</b></div>;

export const ReportPage = () => {
  const { isLoading, loading, notLoading } = useLoading();
  const { reportId } = useParams();
  const request = useRequest((id) => fetchReport(id));
  const { addNotification } = useNotifications();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [tab, setTab] = useState(0);
  const message = useRef({
    json: '',
    html: '',
  });
  const [vendorsKeys, setVendorsKeys] = useState([]);
  const [currVendorId, setCurrVendorId] = useState(null);
  const [vendorsDataFiltered, setVendorsDataFiltered] = useState([]);

  useEffect(() => {
    const vendorsData = data?.data?.vendors || [];
    if (!currVendorId) {
      setVendorsDataFiltered(vendorsData);
    } else {
      setVendorsDataFiltered(
        vendorsData.filter(v => v.vendor.id === currVendorId)
      );
    }
  }, [currVendorId]);

  const basicInfoData = () => {
    return [
      {
        title: 'Date Range',
        value: `${formatDate(data?.start_date)} - ${formatDate(data?.end_date)}`,
      },
      {
        title: 'Fees',
        value: fCurrency(data.data.fees)
      },
      {
        title: 'Commission',
        value: fCurrency(data.data.commision)
      },
      {
        title: 'Created Date',
        value: formatDate(data.created_at)
      },
      {
        title: 'Gross',
        value: fCurrency(data.data.gross)
      },
      {
        title: 'Total',
        value: fCurrency(data.data.total)
      },
      {
        title: 'Rent Included',
        value: data?.include_rent ? 'Yes' : 'No'
      },
      {
        title: 'Discounts',
        value: fCurrency(data.data.discounts)
      },
      {
        title: 'Payout',
        value: fCurrency(data.data.payout)
      },
      {
        title: 'Monthly Fee Included',
        value: data?.include_fees ? 'Yes' : 'No'
      },
      {
        title: 'Net',
        value: fCurrency(data.data.net)
      }
    ]
  }

  const computeVendorsKeys = (vendorsData) => {
    const result = vendorsData.map(v => ({
      id: v.vendor.id,
      label: `${v.vendor.name} | ${v.vendor.business}`
    }));
    setVendorsKeys(result);
  }

  const fetchData = async () => {
    try {
      loading();
      const response = await request(reportId);
      setData(response);
      message.current.json = response.message;
      message.current.html = response.message_html;
      computeVendorsKeys(response.data.vendors);
      setVendorsDataFiltered(response.data.vendors);
      notLoading();
    } catch (e) {
      notLoading();
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleBack = () => navigate(REPORTS_ROUTE);

  const updateMessage = async () => {
    if (message.json === data.message && message.html === data.message_html) {
      addNotification({
        type: 'info',
        message: 'Nothing to update!',
      });
      return;
    }

    try {
      const response = await updateReport(reportId, {
        message: message.current.json,
        message_html: message.current.html,
      });
      const newJsonMsg = response.data.message;
      const newHtmlMsg = response.data.message_html;
      setData({
        ...data,
        message: newJsonMsg,
        message_html: newHtmlMsg,
      });
      message.current.json = newJsonMsg;
      message.current.html = newHtmlMsg;
      addNotification({
        type: 'success',
        message: 'Report was successfully updated!',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
      throw e;
    }
  }

  const onEdit = async () => {
    try {
      if (isEditing) {
        await updateMessage();
      }
      setIsEditing(!isEditing);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }

  const onDelete = async () => {
    if (!window.confirm(`Are you sure you want to delete Report #${reportId}?`)) return;
    try {
      await deleteReport(reportId);
      addNotification({
        type: 'success',
        message: 'Report was successfully deleted!',
      });
      handleBack();
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  const onSend = async () => {
    if (!window.confirm(`Are you sure you want to send Report #${reportId} to each vendor?`)) return;
    try {
      await sendReport(reportId, {
        cc: false,
        message: data.message || '',
        reportId: +reportId,
        vendorId: null,
      });
      addNotification({
        type: 'success',
        message: 'Report was successfully sent!',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  const onSendToVendor = async ({ vendorId, message, cc = false}) => {
    if (!window.confirm(`Are you sure you want to send Report #${reportId} to the selected vendor?`)) return;
    try {
      await sendReportToVendor(reportId, vendorId, {
        cc,
        message,
        reportId: +reportId,
        vendorId: +vendorId,
      });
      addNotification({
        type: 'success',
        message: 'Report was successfully sent!',
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  const onDownload = async () => {
    try {
      await downloadReport(reportId);
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  const onMessageChange = (newMessageVal) => {
    message.current.json = newMessageVal.json;
    message.current.html = newMessageVal.html;
  }

  const ReportBaseInfo = () => (
    <div style={{ background: '#f3f3f3', borderRadius: '1rem', padding: '1rem' }}>
      <Grid container columnSpacing={1} rowSpacing={2}>
        {!!Object.keys(data).length && basicInfoData().map((i, idx) => <Grid key={idx} item xs={12} md={4} align={'center'}>
          <Header value={i.title}/>
          <div>{i.value}</div>
        </Grid>)}
        <Grid item md={12} xs={12} mt={2}>
          <Header value={'Email message'}/>
          <EditorComponent
            onChange={onMessageChange}
            disabled={!isEditing}
            defaultValue={message.current.json}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <AppLayout title={'Reports'}>
      <ReportHeader
        isEditing={isEditing}
        handleBack={handleBack}
        onEdit={onEdit}
        onDelete={onDelete}
        onSend={onSend}
        onDownload={onDownload}
      />
      <Divider sx={{ margin: '1rem 0' }}/>
      <ReportBaseInfo />

      {isLoading && <Stack sx={{ mt: 5 }} direction={'row'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>}
      {!isLoading && <Tabs
        value={tab}
        onChange={(event, newTabIdx) => setTab(newTabIdx)}
        sx={{ margin: '1rem 0' }}
      >
        <Tab label="Vendors" />
        <Tab label="Misc" />
      </Tabs>}
      {!isLoading && <>
        {tab === 0 && <div>
          <Stack mb={2}>
            <Autocomplete
              disablePortal
              onChange={(e, vendor) => setCurrVendorId(vendor ? vendor.id : null)}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              options={vendorsKeys}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} size={'small'} label="Vendor" />}
            />
          </Stack>
          {!!vendorsDataFiltered.length && vendorsDataFiltered.map(
            (v, idx) => <ReportVendorItem
              key={idx}
              data={v}
              onSend={onSendToVendor}
            />
          )}
        </div>}
        {tab === 1 && <div>
          <ReportMiscTable data={data.data.misc || []}/>
        </div>}
      </>}
    </AppLayout>
  );
}