import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  FormControl
} from '@mui/material';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppLayout } from '../../layouts/app';
import {
  VendorCodes,
  VendorInfoItem,
  VendorInfoItemHeader,
  VendorStatus,
  VendorStatusSelect
} from '../../components/vendor';
import Iconify from '../../components/iconify';
import { VENDORS_ROUTE } from '../../router';
import { useRequest } from '../../hooks/useRequest';
import { deleteVendor, fetchVendor, updateVendor } from '../../requests/vendor';
import { useAuth, useNotifications } from '../../providers';
import { VendorTransactionsTable } from '../../components/vendor/VendorTransactionsTable';
import { dateString, ISODate } from '../../utils/formatTime';

export const VendorPage = () => {
  const { user, setUser } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { addNotification } = useNotifications();
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const fetchPromise = useRequest(() => fetchVendor(vendorId));
  const [data, setData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newCodes, setNewCodes] = useState([]);
  const [codesToDelete, setCodesToDelete] = useState([]);
  const [codeName, setCodeName] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [dateFilter, setDateFilter] = useState(null);
  const [notify, setNotify] = useState(false);

  const onAddCode = () => {
    if (!codeName) return;
    const idx = findCodeIndexByName(codeName);
    if (idx !== -1) return;
    setNewCodes([
      ...newCodes,
      { name: codeName }
    ]);
    setCodeName('');
  }

  const findCodeIndexByName = (name) => newCodes.findIndex(c => c.name === name);

  const onDeleteCode = (code) => {
    const findIndex = findCodeIndexByName(code);
    if (findIndex === -1) return;
    const codes = [
      ...newCodes.slice(0, findIndex),
      ...newCodes.slice(findIndex + 1),
    ];
    setNewCodes(codes);
    const codeObj = newCodes[findIndex];
    if (codeObj.id) {
      setCodesToDelete([
        ...codesToDelete,
        codeObj
      ]);
    }
  }

  const fetchData = async () => {
    try {
      const data = await fetchPromise();
      setData(data);
      setNotify(data.email_notification);
      setNewCodes(data.codes);
      setNewStatus(data.status);
    } catch (e) {
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error'
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onEditClick = () => {
    setIsEditing(!isEditing);
  }

  const endUpdating = async () => {
    setUpdateLoading(false);
    setCodeName('');
    setIsEditing(false);
    setCodesToDelete([]);
    setNewCodes([]);
    await fetchData();
  }

  const needsUpdate = (formData) => {
    const mainData = {
      name: data.name,
      business: data.business,
      phone: data.phone,
      rent: data.rent,
      rate: data.rate,
      email: data.email,
      notes: data.notes,
      notify: data.email_notification,
      status: data.status,
      codes: data.codes,
    };
    const newCodesList = newCodes.filter(c => !c.id);
    const newData = {
      name: formData.name,
      business: formData.business,
      phone: formData.phone,
      rent: +formData.rent,
      rate: +formData.rate,
      email: formData.email,
      notes: formData.notes,
      notify,
      status: newStatus,
      codes: codesToDelete.length || newCodesList.length ? false : data.codes,
    };
    return JSON.stringify(mainData) !== JSON.stringify(newData);
  }

  const onBasicInfoUpdate = async (formData) => {
    if (!needsUpdate(formData)) {
      addNotification({
        message: 'Nothing to update!',
        type: 'info'
      });
      return;
    }
    const reqData = {
      ...formData,
      status: newStatus,
      codes: data.codes,
      new_codes: newCodes.filter(c => !c.id),
      codes_to_delete: codesToDelete,
      email_notification: notify,
    };
    try {
      setUpdateLoading(true);
      await updateVendor(data.id, reqData);
      setUpdateLoading(false);
      await endUpdating();
      setUser({
        ...reqData,
        id: data.id,
      });
    } catch (e) {
      addNotification({
        type: 'error',
        message: e?.message || 'Something went wrong!',
      });
      setUpdateLoading(false);
    }
  }

  const onCancelClick = () => {
    setIsEditing(false);
    setNewStatus(data.status);
  }

  const handleBack = () => {
    navigate(VENDORS_ROUTE);
  }

  const onStatusChange = (event) => {
    setNewStatus(event.target.value);
  }

  const VendorBaseInfo = (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Name'} />
        {isEditing && <TextField
          size={'small'}
          defaultValue={data.name}
          fullWidth
          {...register('name')}
        />}
        {!isEditing && <VendorInfoItem value={data.name} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Business'} />
        {isEditing && <TextField
          size={'small'}
          defaultValue={data.business}
          fullWidth
          {...register('business')}
        />}
        {!isEditing && <VendorInfoItem value={data.business} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Phone'} />
        {isEditing && <TextField
          size={'small'}
          defaultValue={data.phone}
          fullWidth
          {...register('phone')}
        />}
        {!isEditing && <VendorInfoItem value={data.phone} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Rent'} />
        {isEditing && <TextField
          size={'small'}
          type={'number'}
          defaultValue={data.rent}
          fullWidth
          {...register('rent')}
        />}
        {!isEditing && <VendorInfoItem value={data.rent} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Rate'} />
        {isEditing && <TextField
          size={'small'}
          type={'number'}
          defaultValue={data.rate}
          fullWidth
          {...register('rate')}
        />}
        {!isEditing && <VendorInfoItem value={data.rate} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'E-mail'} />
        {isEditing && <TextField
          size={'small'}
          type={'email'}
          defaultValue={data.email}
          fullWidth
          {...register('email')}
        />}
        {!isEditing && <VendorInfoItem value={data.email} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Notes'} />
        {isEditing && <TextField
          size={'small'}
          defaultValue={data.notes}
          fullWidth
          multiline
          rows={4}
          {...register('notes')}
        />}
        {!isEditing && <VendorInfoItem value={data.notes} />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Codes'} />
        {isEditing &&
          <>
            <OutlinedInput
              size={'small'}
              value={codeName}
              onChange={(e) => setCodeName(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={onAddCode}
                    edge="end"
                  >
                    <Iconify icon={'mdi:plus'} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <div style={{ marginTop: '.5rem' }}>
              {data.codes ? <VendorCodes
                size={'medium'}
                codes={newCodes.map(c => c.name)}
                onDelete={onDeleteCode} />: null}
            </div>
          </>
        }
        {!isEditing && <VendorInfoItem header={'Codes'} value={
          data.codes ? <VendorCodes codes={data.codes.map(c => c.name)} />: null
        } />}
      </Grid>
      <Grid item xs={12} md={4}>
        <VendorInfoItemHeader value={'Notify'} />
        <FormControl variant="outlined" size="small">
          <Select
            disabled={!isEditing}
            value={notify}
            onChange={(e) => setNotify(e.target.value)}
          >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const onDateFilter = (value) => {
    setDateFilter(value);
  }

  const onDeleteVendor = async () => {
    if (!window.confirm('Are you sure you want to delete given vendor?')) return;
    try {
      await deleteVendor(data.id);
      addNotification({
        message: 'Vendor was successfully deleted!',
        type: 'success'
      });
      handleBack();
    } catch (e) {
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error'
      });
    }
  }

  return (
    <AppLayout
      pageHeader={`Vendor ${data.name ? data.name : 'Loading...'}`}
      title={`Vendor ${data.name ? data.name : 'Loading...'}`}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Button
          variant="outlined"
          startIcon={<Iconify icon={'mdi:chevron-left'} />}
          onClick={handleBack}
          size={'small'}
        >
          Back to vendors
        </Button>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {data.status && !isEditing && <VendorStatus status={data.status} />}
          {isEditing && <>
            <b>Status:</b>
            <VendorStatusSelect value={newStatus} onChange={onStatusChange} />
          </>}
          {!isEditing && !!Object.keys(data).length && <Button
            variant={'contained'}
            color={'warning'}
            onClick={onEditClick}
          >Edit</Button>}
          {isEditing && <Button
            onClick={handleSubmit(onBasicInfoUpdate)}
            variant={'contained'}
            color={'success'}
            disabled={updateLoading}
          >
            {updateLoading ? <CircularProgress size={24} /> : 'Update'}
          </Button>}

          {isEditing && <Button
            variant={'contained'}
            color={'warning'}
            onClick={onCancelClick}
          >Cancel</Button>}
          {!!Object.keys(data).length && user?.id !== data?.id && <Button
            variant={'contained'}
            color={'error'}
            onClick={onDeleteVendor}
          >Delete</Button>}
        </Stack>
      </Stack>

      <Divider sx={{ margin: '1rem 0'}} />
      {VendorBaseInfo}
      <Divider sx={{ margin: '1rem 0'}} />

      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'}>
        <h1>Transactions</h1>
        <DatePicker
          size={'small'}
          value={dateFilter}
          onChange={onDateFilter}
          dateFormat="yyyy-mm-dd"
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Stack>

      <VendorTransactionsTable
        dateFilter={dateFilter ? dateString(dateFilter) : null}
      />
    </AppLayout>
  );
}
