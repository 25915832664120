import { useEffect, useState } from 'react';
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import {
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from '../../../requests/auth';
import { useNotifications } from '../../../providers';
import { LOGIN_ROUTE } from '../../../router';

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const [params ] = useSearchParams();

  useEffect(() => {
    if (!params.get('token')) {
      addNotification({
        type: 'error',
        message: 'Wrong token!',
      });
      navigate(LOGIN_ROUTE);
    }
  }, []);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();
  const [email, setEmail] = useState('');

  const handleClick = async () => {
    try {
      setLoading(true);
      await resetPassword({
        token: params.get('token'),
        password,
        password_confirmation: passwordConfirmation,
        email,
      });
      navigate(LOGIN_ROUTE);
      setLoading(false);
    } catch (e) {
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          type={'email'}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          type={'password'}
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <TextField
          name="password_confirmation"
          type={'password'}
          label="Password Confirmation"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        onClick={handleClick}
        sx={{ marginTop: '.5rem' }}
      >
        Reset password
      </LoadingButton>
    </>
  );
}
