import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  TableHead,
  Table,
  TableCell,
  Stack, TableBody, Button, TableRow, Card
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import { fShortenNumber } from '../utils/formatNumber';
import { AppLayout } from '../layouts/app';
import { pageTitle } from '../utils/seo';
import { useAuth, useNotifications } from '../providers';
import { useLoading, useRequest } from '../hooks';
import { metrics, recentVendors, topVendors } from '../requests/admin';
import { VendorCodes, VendorStatus } from "../components/vendor";
import { VENDORS_ROUTE } from "../router";
import { TotalCommissionChart, TotalSalesChart } from '../components/dashboard';

export default function DashboardAppPage() {
  const navigator = useNavigate();
  const { user } = useAuth();
  const { isLoading, notLoading, loading } = useLoading();
  const request = useRequest(() => metrics());
  const recentVendorsReq = useRequest(() => recentVendors());
  const topVendorsReq = useRequest(() => topVendors());
  const { addNotification } = useNotifications();
  const [data, setData] = useState({});
  const [recVendors, setRecentVendors] = useState([]);
  const [topSalesVendors, setTopSalesVendors] = useState([]);

  const goToVendors = () => navigator(VENDORS_ROUTE);

  const fetchData = async () => {
    try {
      loading();
      const response = await request();
      setData(response);
      const recentVendors = await recentVendorsReq();
      setRecentVendors(recentVendors.items);
      const topVendorsResponse = await topVendorsReq();
      setTopSalesVendors(topVendorsResponse.items);
      notLoading();
    } catch (e) {
      console.log(e);
      notLoading();
      addNotification({
        type: 'error',
        message: 'Something went wrong!',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AppLayout>
      <Helmet>
        <title>{pageTitle('Dashboard')}</title>
      </Helmet>

      <Container maxWidth="xl" disableGutters>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Welcome, {user?.business || user?.name}!
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="TOTAL VENDORS"
              total={
                isLoading ? <CircularProgress /> : fShortenNumber(data?.total?.vendors || 0)
              }
              icon={'carbon:summary-kpi'}
              deltaPercent={data?.weekly_change?.vendors || 0}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="TOTAL COMMISSION"
              total={isLoading ? <CircularProgress /> : fShortenNumber(data?.total?.commision || 0)}
              icon={'mdi:chart-pie'}
              deltaPercent={fShortenNumber(data?.weekly_change?.commision || 0)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="SALES"
              total={isLoading ? <CircularProgress /> : fShortenNumber(data?.total?.sales || 0)}
              icon={'mdi:cash'}
              deltaPercent={fShortenNumber(data?.weekly_change?.sales || 0)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="SALES COUNT"
              total={
                isLoading ? <CircularProgress /> : fShortenNumber(data?.total?.transactions || 0)
              }
              deltaPercent={fShortenNumber(data?.weekly_change?.transactions || 0)}
              icon={'eos-icons:performance'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={7}>
            <Card sx={{ p: 2, borderRadius: '12px 12px 0 0' }}>
              <TotalCommissionChart />
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <Card sx={{ p: 2, borderRadius: '12px 12px 0 0' }}>
              <TotalSalesChart />
            </Card>
          </Grid>

          <Grid item xs={12} md={7} lg={7}>
            <Card sx={{ width: '100%', borderRadius: '12px 12px 0 0' }}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ p: 2 }}
              >
                <div>
                  <Typography variant={'h6'}>Recently Added Vendors</Typography>
                </div>
                <Button onClick={goToVendors} variant={'contained'} color={'primary'}>See All</Button>
              </Stack>
              {isLoading && <Stack
                direction={'row'}
                sx={{ p: 4 }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress />
              </Stack>}
              {!isLoading && <Table>
                <TableHead>
                  <TableCell>STATUS</TableCell>
                  <TableCell>CODES</TableCell>
                  <TableCell>NAME</TableCell>
                  <TableCell>BUSINESS</TableCell>
                </TableHead>
                <TableBody>
                  {!!recVendors.length && recVendors.map((v) => {
                    return <TableRow hover key={v.id}>
                      <TableCell><VendorStatus status={v.status} /></TableCell>
                      <TableCell><VendorCodes codes={v.codes.map((c) => c.name)} /></TableCell>
                      <TableCell>{v.name}</TableCell>
                      <TableCell>{v.business}</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>}
            </Card>
          </Grid>

          <Grid item xs={12} md={5} lg={5}>
            <Card sx={{ width: '100%', borderRadius: '12px 12px 0 0' }}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ p: 2 }}
              >
                <div>
                  <Typography variant={'h6'}>Top Performing Vendors</Typography>
                </div>
                <Button onClick={goToVendors} variant={'contained'} color={'primary'}>See All</Button>
              </Stack>
              {isLoading && <Stack
                direction={'row'}
                sx={{ p: 4 }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress />
              </Stack>}
              {!isLoading && <Table>
                <TableHead>
                  <TableCell>NAME</TableCell>
                  <TableCell>SALES</TableCell>
                  <TableCell>% OF COMMISSION</TableCell>
                </TableHead>
                <TableBody>
                  {!!topSalesVendors.length && topSalesVendors.map((v) => {
                    return <TableRow hover key={v.id}>
                      <TableCell>{v.name}</TableCell>
                      <TableCell>{v.total_sales}</TableCell>
                      <TableCell>{v.rate}%</TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  );
}
