import { Button, Grid, Stack } from '@mui/material';
import { AppLayout } from '../layouts/app';
import { useAuth } from '../providers';
import Iconify from '../components/iconify';

export const ProfilePage = () => {
  const { user } = useAuth();

  return (
    <AppLayout pageHeader={'Profile'} title={'Profile'}>
      <Grid container>
        <Grid item md={3} sm={12}>
          <div><b>Name</b></div>
          <span>{user.name}</span>
        </Grid>
        <Grid item md={3} sm={12}>
          <div><b>Business</b></div>
          <span>{user.business}</span>
        </Grid>
        <Grid item md={3} sm={12}>
          <div><b>Email</b></div>
          <span>{user.email}</span>
        </Grid>
      </Grid>
      <Stack direction={'row'} alignItems={'center'} spacing={2} mt={3}>
        {/* <Button */}
        {/*  variant={'contained'} */}
        {/*  color={'warning'} */}
        {/* >RESET PASSWORD</Button> */}
        {/* <Button variant={'contained'} startIcon={<Iconify icon={'mdi:key'} />}>SHOW API KEY</Button> */}
      </Stack>
    </AppLayout>
  );
}