import { useState } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {
  Stack,
  TextField, Snackbar, Alert, Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { forgotPassword } from '../../../requests/auth';

export default function ForgotPasswordForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    text: '',
    toastIsOpen: false,
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await forgotPassword({
        email,
      });
      setLoading(false);
    } catch (e) {
      setError({
        text: e.message ?? 'Something went wrong!',
        toastIsOpen: true,
      });
      setLoading(false);
    }
  };

  const onErrorTimeout = () => {
    setError({
      ...error,
      toastIsOpen: false,
    });
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link
          variant="subtitle2"
          underline="hover"
          sx={{ cursor: 'pointer' }}
        >
          <NavLink to={'/login'}>Log in</NavLink>
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        onClick={handleClick}
      >
        Send reset link
      </LoadingButton>

      <Snackbar
        open={error.toastIsOpen}
        onClose={onErrorTimeout}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          variant='filled'
          severity={'error'}
          elevation={5}
        >{error.text}</Alert>
      </Snackbar>
    </>
  );
}
