import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover
} from '@mui/material';
import account from '../../_mock/account';
import { useAuth } from '../../providers/authProvider';
import { LOGIN_ROUTE, PROFILE_ROUTE } from '../../router';
import { useNotifications } from '../../providers';
import { logout } from '../../requests/auth';

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
];

export default function AccountPopover() {
  const { user } = useAuth();
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = async () => {
    try {
      await logout();
      addNotification({
        message: 'Successfully logged out!',
        type: 'info'
      })
    } catch (e) {
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error'
      })
    }

    navigate(`${LOGIN_ROUTE}`);
  }

  const onProfileClick = () => {
    navigate(`${PROFILE_ROUTE}`);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          border: 'solid',
          borderColor: (theme) => alpha(theme.palette.grey[800], 0.2),
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[800], 0.2),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={onProfileClick}>
            Profile
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
