import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { DASHBOARD_ROUTE } from '../../router';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 150,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.65 60.27">
        <path className="a"
              d="M17.16,34.33a2,2,0,0,1-1.41-.59L4.1,22.09a7,7,0,0,1,0-9.86L15.75.59a2,2,0,0,1,2.83,0l7.24,7.24h0l7.93,7.93a2,2,0,0,1,0,2.83L18.58,33.74A2,2,0,0,1,17.16,34.33Zm0-29.5L6.93,15.06a3,3,0,0,0,0,4.2L17.16,29.5,29.5,17.16Z"/>
        <path className="a"
              d="M17.16,48.62A2,2,0,0,1,15.75,48L.59,32.87A2,2,0,0,1,.59,30L15.75,14.88a2,2,0,0,1,2.83,0l12,12a6.41,6.41,0,0,1,0,9.06l-12,12A2,2,0,0,1,17.16,48.62ZM4.83,31.46,17.16,43.79,27.8,33.16a2.41,2.41,0,0,0,0-3.4L17.16,19.12Z"/>
        <path className="a"
              d="M16.21,19.42A2,2,0,0,1,14.81,16l7.84-7.84A2,2,0,0,1,25.47,11l-7.85,7.85A2,2,0,0,1,16.21,19.42Z"/>
        <path className="a"
              d="M10.27,41.18a2,2,0,0,1-1.41-3.42l7.84-7.84a2,2,0,1,1,2.84,2.82L11.68,40.6A2,2,0,0,1,10.27,41.18Z"/>
        <path className="a"
              d="M43.21,49.71l2.53-.24a1,1,0,0,1,1.09.87c.53,4.3,1.37,5.81,3.3,5.81,1.78,0,2.78-1.25,2.78-3.89,0-3.36-2.26-5.66-4.66-8.54-2.64-3.17-5.47-6.29-5.47-11.42s2.54-8.21,7-8.21c5,0,6.71,3.16,7.42,8.63a1,1,0,0,1-.88,1.14l-2.57.3a1,1,0,0,1-1.11-.88c-.49-4.08-1.31-4.91-2.87-4.91s-2.21,1.3-2.21,3.5c0,3.79,2.21,6,4.56,8.78,2.69,3.12,5.57,6,5.57,11.38s-2.88,8.26-7.63,8.26c-5.63,0-7.09-3.73-7.76-9.46A1,1,0,0,1,43.21,49.71Z"/>
        <path className="a"
              d="M62.17,24.42h2.9a1,1,0,0,1,1,1V38.1a1,1,0,0,0,1,1h3.28a1,1,0,0,0,1-1V25.42a1,1,0,0,1,1-1h2.9a1,1,0,0,1,1,1V58.94a1,1,0,0,1-1,1h-2.9a1,1,0,0,1-1-1V44.14a1,1,0,0,0-1-1H67.06a1,1,0,0,0-1,1v14.8a1,1,0,0,1-1,1h-2.9a1,1,0,0,1-1-1V25.42A1,1,0,0,1,62.17,24.42Z"/>
        <path className="a"
              d="M86.06,24.27h3.2a1,1,0,0,1,1,.84l5.62,33.67a1,1,0,0,1-1,1.16H92a1,1,0,0,1-1-.86l-1.1-7.6a1,1,0,0,0-1-.86H86a1,1,0,0,0-1,.86L84,59.08a1,1,0,0,1-1,.86H80.4a1,1,0,0,1-1-1.17l5.66-33.67A1,1,0,0,1,86.06,24.27Zm2.25,22.85a1,1,0,0,0,1-1.12C89,43.35,88.17,37.63,88,36.66c-.19-1.44-.34-2.69-.48-4.42h-.1c-.14,1.78-.29,3-.48,4.42-.12,1-1,6.69-1.3,9.34a1,1,0,0,0,1,1.13Z"/>
        <path className="a"
              d="M99,25.42a1,1,0,0,1,1-1h5.58c4.71,0,8.07,2.88,8.07,10.75,0,4.77-1.14,7.75-3.15,9.17a1,1,0,0,0-.36,1l3.51,13.3a1,1,0,0,1-1,1.26H110a1,1,0,0,1-1-.75l-3.17-12.32a1,1,0,0,0-1-.75h-.1a1,1,0,0,0-1,1V58.94a1,1,0,0,1-1,1H100a1,1,0,0,1-1-1Zm6.53,16.91c2.3,0,3.26-1.78,3.26-7.06,0-5-.91-6.58-3.31-6.58h-.73a1,1,0,0,0-1,1V41.32a1,1,0,0,0,1,1Z"/>
        <path className="a"
              d="M118.37,24.42h9.76a1,1,0,0,1,1,1v2.27a1,1,0,0,1-1,1h-4.87a1,1,0,0,0-1,1v8.37a1,1,0,0,0,1,1H126a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1h-2.71a1,1,0,0,0-1,1V54.76a1,1,0,0,0,1,1h5.2a1,1,0,0,1,1,1v2.18a1,1,0,0,1-1,1h-10.1a1,1,0,0,1-1-1V25.42A1,1,0,0,1,118.37,24.42Z"/>
        <path className="a"
              d="M140.84,24.42h10a1,1,0,0,1,1,1v2.27a1,1,0,0,1-1,1h-5.15a1,1,0,0,0-1,1v8.56a1,1,0,0,0,1,1h3.14a1,1,0,0,1,1,1v2.08a1,1,0,0,1-1,1h-3.14a1,1,0,0,0-1,1V58.94a1,1,0,0,1-1,1h-2.9a1,1,0,0,1-1-1V25.42A1,1,0,0,1,140.84,24.42Z"/>
        <path className="a"
              d="M154.77,25.42a1,1,0,0,1,1-1h5.58c4.71,0,8.07,2.88,8.07,10.75,0,4.77-1.14,7.75-3.15,9.17a1,1,0,0,0-.36,1l3.51,13.3a1,1,0,0,1-1,1.26h-2.73a1,1,0,0,1-1-.75l-3.17-12.32a1,1,0,0,0-1-.75h-.1a1,1,0,0,0-1,1V58.94a1,1,0,0,1-1,1h-2.75a1,1,0,0,1-1-1Zm6.53,16.91c2.3,0,3.26-1.78,3.26-7.06,0-5-.91-6.58-3.31-6.58h-.73a1,1,0,0,0-1,1V41.32a1,1,0,0,0,1,1Z"/>
        <path className="a"
              d="M172.33,42.18c0-13.15,2.74-18.1,8.3-18.1s8.26,4.94,8.26,18.1-2.78,18.1-8.26,18.1S172.33,55.33,172.33,42.18Zm11.57,0c0-9.6-.58-13.58-3.26-13.58s-3.31,4-3.31,13.58.62,13.73,3.31,13.73S183.9,51.78,183.9,42.18Z"/>
        <path className="a"
              d="M193.64,24.42h2.93a1,1,0,0,1,1,.75l3.74,14.47c.63,2.59,1.34,5.52,1.87,8.54h0c-.14-3-.24-7.2-.24-8.78v-14a1,1,0,0,1,1-1h2.22a1,1,0,0,1,1,1V58.94a1,1,0,0,1-1,1h-2.27a1,1,0,0,1-1-.74l-4.22-15.58a63.74,63.74,0,0,1-2-8.06h-.1c.19,1.87.24,7.2.24,8.4v15a1,1,0,0,1-1,1h-2.22a1,1,0,0,1-1-1V25.42A1,1,0,0,1,193.64,24.42Z"/>
        <path className="a"
              d="M214,28.69h-2.85a1,1,0,0,1-1-1V25.42a1,1,0,0,1,1-1h12.54a1,1,0,0,1,1,1v2.27a1,1,0,0,1-1,1h-2.85a1,1,0,0,0-1,1V58.94a1,1,0,0,1-1,1H216a1,1,0,0,1-1-1V29.69A1,1,0,0,0,214,28.69Z"/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to={DASHBOARD_ROUTE} component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
