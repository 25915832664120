import { Chip } from '@mui/material';

export const VendorCodes = ({ codes, onDelete, size = 'small' }) => {
  const getStyles = () => {
    let res = {
      fontWeight: 'bold',
    };
    if (codes.length > 1) {
      res = {
        ...res,
        marginRight: '4px', fontWeight: 'bold', mb: 1
      };
    }
    return res;
  }

  return (
    <>
      {codes.map(c => {
        const props = {
          key: c,
          size,
          label: c,
          sx: {...getStyles()}
        };
        if (onDelete) {
          return <Chip onDelete={() => onDelete(c)} {...props} />
        }
        return <Chip {...props} />
      })}
    </>
  )
}