import { makeGetAuthRequest } from './common';

const PREFIX = 'admin';

export const metrics = () => makeGetAuthRequest(`/${PREFIX}/metrics`);
export const recentVendors = () => makeGetAuthRequest(`/${PREFIX}/recent-vendors`);
export const topVendors = () => makeGetAuthRequest(`/${PREFIX}/top-vendors`);

export const totalCommissionWeekChart = () => makeGetAuthRequest(`/${PREFIX}/charts/total-commission/week`);
export const totalCommissionMonthChart = () => makeGetAuthRequest(`/${PREFIX}/charts/total-commission/month`);
export const totalSalesMonthChart = () => makeGetAuthRequest(`/${PREFIX}/charts/total-sales/month`);
