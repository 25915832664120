import { useEffect, useState } from 'react';
import { Button, Card, CardActions, Grid, Typography, Stack } from '@mui/material';
import { AppLayout } from '../layouts/app';
import { disableSquare, getSquareOauthLink } from '../requests/square';
import { useNotifications } from '../providers';
import { activeIntegrations } from '../requests/integrations';
import Iconify from '../components/iconify';
import { useLoading } from '../hooks';

const IntegrationStatus = ({ active }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <div style={{ width: '12px', height: '12px', borderRadius: '50%', background: active ? 'green' : 'gray' }} />
      <div style={{ fontWeight: 'bold', color: active ? 'green' : 'gray'}}>
        {active ? 'Enabled' : 'Disabled'}
      </div>
    </Stack>
  );
}

export const IntegrationsPage = () => {
  const [data, setData] = useState({});
  const { addNotification } = useNotifications();
  const { isLoading, loading, notLoading } = useLoading();
  const enableSquare = async () => {
    const response = await getSquareOauthLink();
    const link = response.data.link;
    window.location.href = link;
  };

  const disableSquareHandler = async () => {
    try {
      loading();
      await disableSquare();
      setData({
        ...data,
        square: false
      });
      notLoading();
    } catch (error) {
      addNotification({
        type: 'error',
        message: error.message || 'Something went wrong!'
      });
      notLoading();
    }
  }


  useEffect(() => {
    activeIntegrations()
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        addNotification({
          type: 'error',
          message: error.message || 'Something went wrong!'
        });
      })
  }, []);
  
  return (
    <AppLayout title={'Integrations'} pageHeader={'Integrations'}>
      <Grid container spacing={2}>
        <Grid item lg={4} md={6} sm={12}>
          <Card sx={{ p: 2 }}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ px: 1, mb: 3 }}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Iconify icon={'mdi:square-inc'} width={32} />
                <Typography variant={'h4'}>
                  Square
                </Typography>
              </Stack>
              <IntegrationStatus active={data.square} />
            </Stack>
            <CardActions>
              {!data.square && <Button
                fullWidth
                variant='outlined'
                onClick={enableSquare}
                color={'success'}
              >Enable</Button>}
              {data.square && <Button
                fullWidth
                variant='outlined'
                onClick={disableSquareHandler}
                color={'error'}
                disabled={isLoading}
              >Disable</Button>}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </AppLayout>
  );
}