import { useState } from 'react';

export const useLoading = (defaultLoading = false) => {
  const [isLoading, setIsLoading] = useState(!!defaultLoading);

  const loading = () => setIsLoading(true);
  const notLoading = () => setIsLoading(false);

  return {
    isLoading: Boolean(isLoading),
    loading,
    notLoading
  };
}