import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { authService } from '../services';
import { useAuth } from '../providers/authProvider';
import { DASHBOARD_ROUTE } from '../router';

export const NotAuthGuard = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const accessToken = authService.getAccessToken();

  useEffect(() => {
    if (accessToken && !authService.isAccessTokenExpired()) {
      navigate(DASHBOARD_ROUTE, { replace: true });
    }
  }, []);

  if (accessToken && !authService.isAccessTokenExpired()) {
    return null;
  }

  return (
    <>{children}</>
  );
}