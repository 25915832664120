import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useMountedEffect, useRequest, useDebouncedData } from '../../hooks';
import Iconify from '../iconify';
import { useNotifications } from '../../providers';
import {BooleanFilter, MultiSelect, SearchFilter} from "../filter";

export const DataTable = (
  {
    columnsConfig,
    dataCallback,
    defaultPerPage = 10,
    defaultSorting = [],
    defaultFilters = []
  }
) => {
  const request = useRequest((params) => dataCallback(params));
  const page = useRef(1);
  const [perPage] = useState(defaultPerPage);
  const [loadMore, setLoadMore] = useState(true);
  const [items, setItems] = useState([]);
  const [sort, setSort] = useState(defaultSorting);
  const [filters, setFilters] = useState(defaultFilters);
  const debouncedFilters = useDebouncedData(filters, 500);
  const debouncedSort = useDebouncedData(sort, 500);
  const { addNotification } = useNotifications();

  useEffect(() => {
    fetchData();
  }, []);

  useMountedEffect(() => {
    page.current = 1;
    fetchData();
  }, [debouncedSort, debouncedFilters]);

  const formatFiltersForReq = () => {
    return filters.map(f => {
      const name = f.name;
      const type = f.type;
      let value = f.value;
      if (f.type === 'multi_select') {
        value = f.value.map(v => v.value);
      }
      return { name, type, value };
    });
  }

  const fetchData = async () => {
    request({
      page: page.current,
      per_page: perPage,
      sort,
      filter: formatFiltersForReq(),
    })
      .then(data => {
        if (page.current === 1) {
          setItems(data);
        } else {
          setItems([
            ...items,
            ...data
          ]);
        }
        setLoadMore(data.length === perPage);
      })
      .catch(error => {
        addNotification({
          type: 'error',
          message: error.message,
        });
      })
  }

  const onLoadMore = async () => {
    page.current += 1;
    await fetchData();
  }

  // SORT FUNCTIONS
  const getSortDirection = (fieldName) => {
    const idx = getSortIdx(fieldName);
    if (idx !== -1) {
      return sort[idx].direction;
    }
    return 'asc';
  };

  const addSort = (field, direction) => {
    const sortItem = { field, direction };
    setSort([
      ...sort,
      sortItem,
    ]);
  };

  const sortExists = (sortName) => {
    const index = sort.findIndex(s => s.field === sortName);
    return index !== -1;
  };

  const getSortIdx = (fieldName) => sort.findIndex(s => s.field === fieldName);

  const changeSort = (fieldName) => {
    const currentSortIdx = getSortIdx(fieldName);
    const currentSort = sort[currentSortIdx];
    const currentDirection = currentSort.direction;
    const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
    setSort([
      ...sort.slice(0, currentSortIdx),
      {
        field: fieldName,
        direction: newDirection
      },
      ...sort.slice(currentSortIdx + 1)
    ]);
  }

  const onRequestSort = (event, fieldName) => {
    if (!sortExists(fieldName)) {
      addSort(fieldName, 'asc');
    } else {
      changeSort(fieldName);
    }
  }

  const clearSort = () => setSort([]);
  const clearFilter = () => setFilters([]);

  // CORE FUNCTIONS
  const isToolbarActionsVisible = () => !!sort.length || filters.length;

  const getFilterIndex = (filterName) => {
    return filters.findIndex(f => f.name === filterName);
  }

  const findFilter = (name) => {
    const idx = getFilterIndex(name);
    if (idx === -1) {
      throw new Error('Something went wrong during filter search!');
    }
    return [
      filters[idx],
      idx
    ];
  }

  const updateFilter = (newFilter, idx) => {
    setFilters([
      ...filters.slice(0, idx),
      newFilter,
      ...filters.slice(idx + 1)
    ]);
  }

  const onChangeSearchFilterHandler = (value, filterName) => {
    const [filter, idx] = findFilter(filterName);
    const newFilter = {
      ...filter,
      value,
    };
    setFilters([
      ...filters.slice(0, idx),
      newFilter,
      ...filters.slice(idx + 1)
    ]);
  }

  const onChangeBooleanFilterHandler = (filterName) => {
    const [filter, idx] = findFilter(filterName);
    const newFilter = {
      ...filter,
      value: !filter.value,
    };
    updateFilter(newFilter, idx);
  }

  const onChangeMultiSelectFilter = (event, filterName) => {
    const [filter, idx] = findFilter(filterName);
    const newFilter = {
      ...filter,
      value: event.target.value,
    };
    updateFilter(newFilter, idx);
  }

  const onDeleteMultiSelectFilterItem = (item, filterName) => {
    const [filter, idx] = findFilter(filterName);
    const newValue = filter.value.filter(i => item.value !== i.value);
    updateFilter({
      ...filter,
      value: newValue
    }, idx);
  }

  const onClearMultiSelectFilter = (filterName) => {
    const [filter, idx] = findFilter(filterName);
    updateFilter({
      ...filter,
      value: []
    }, idx);
  }

  const buildFilter = (filter) => {
    if (filter.type === 'search') {
      return <SearchFilter
        key={filter.title}
        value={filter.value}
        onClear={() => onChangeSearchFilterHandler('', filter.name)}
        onChange={(e) => onChangeSearchFilterHandler(e.target.value, filter.name)}
        label={filter.title}
      />;
    }
    if (filter.type === 'boolean') {
      return <BooleanFilter filter={filter} onChange={() => onChangeBooleanFilterHandler(filter.name)} />;
    }
    if (filter.type === 'multi_select') {
      return <MultiSelect
        key={filter.title}
        filter={filter}
        onChange={(e) => onChangeMultiSelectFilter(e, filter.name)}
        onClear={() => onClearMultiSelectFilter(filter.name)}
        onDeleteItem={() => onDeleteMultiSelectFilterItem(filter.value, filter.name)}
      />;
    }
    return null;
  }

  return (
    <div>
      <Stack direction={'row'} alignItems={'center'} spacing={2} mb={2} justifyContent={'space-between'}>
        <Stack direction={'row'} alignItems={'center'} spacing={2} flexWrap={'wrap'}>
          {!!filters.length && filters.map(buildFilter)}
        </Stack>
        {isToolbarActionsVisible() && <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {!!sort.length && <Button
            variant={'outlined'}
            startIcon={<Iconify icon={'mdi:close'} /> }
            onClick={clearSort}
          >
            Reset sorting
          </Button>}
        </Stack>}
      </Stack>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnsConfig.map((i, idx) => (
                  <TableCell
                    variant={'head'}
                    key={idx}
                    align={i?.headerAlign ? i.headerAlign : 'inherit'}
                  >
                    {i.sortable &&
                      <TableSortLabel
                        active={sortExists(i.sortName)}
                        direction={getSortDirection(i.sortName)}
                        onClick={(e) => onRequestSort(e, i.sortName)}
                      >
                        {i.title}
                        </TableSortLabel>}
                    {!i.sortable && <span>{i.title}</span>}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!items.length && items.map(item => (
                <TableRow key={item.id} hover>
                  {columnsConfig.map((col, idx) => (
                    <TableCell
                      width={col?.width ? col.width : 'auto'}
                      key={idx}
                      align={col?.valueAlign ? col.valueAlign : 'inherit'}
                    >{col.value(item)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Stack direction={'row'} justifyContent={'center'} sx={{ marginTop: '10px' }}>
        {loadMore && <Button variant={'contained'} onClick={onLoadMore}>
          LOAD MORE
        </Button>}
      </Stack>
    </div>
  );
}