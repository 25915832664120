export const DASHBOARD_ROUTE = '/';
export const VENDORS_ROUTE = '/vendors';
export const REPORTS_ROUTE = '/reports';
export const TRANSACTIONS_ROUTE = '/transactions';
export const PROFILE_ROUTE = '/profile';
export const LOGIN_ROUTE = '/login';
export const BILLING_ROUTE = '/billing';
export const SQUARE_ROUTE = '/square';
export const INTEGRATIONS_ROUTE = '/integrations';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset';