import { format, getTime, formatDistanceToNow, parseISO } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'MMM dd yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function commonDate(date) {
  return fDate(date, 'MM/dd/yyyy');
}

export function ISODate(date) {
  return new Date(date).toISOString();
}

export function dateString(date) {
  const dateObj = date ? new Date(date).getTime() : new Date().getTime();
  return fDate(dateObj, 'dd-MM-yyyy');
}

export function formatDate(date) {
  const dateObj = new Date(date).getTime();
  return fDate(dateObj, 'MMM dd, yyyy');
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'MMM dd yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

const monthsMap = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}

export const lastMonthNamesList = (amount = 6) => {
  const currentMonth = new Date().getMonth();
  const maxMonthIdx = amount - 1;
  if (currentMonth < maxMonthIdx) {
    return [
      ...Object.values(monthsMap).slice(-(currentMonth - 1)),
      ...Object.values(monthsMap).slice(0, currentMonth + 1),
    ];
  }
  return Object.values(monthsMap).slice(currentMonth - maxMonthIdx, currentMonth + 1);
}

const processNumberWithZero = (number) => {
  if (number < 10) { return `0${number}`; }
  return number;
}

const lastWeeksList = (amount = 6) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const weekRanges = [];
  for (let i = 0; i < amount; i += 1) {
    const endOfWeek = new Date(today);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() - 7 * i);
    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 6 - 7 * i);
    weekRanges.push({ start: startOfWeek, end: endOfWeek });
  }
  return weekRanges.reverse();
}

export const lastWeeksListShort = (amount = 6) => {
  const ranges = lastWeeksList(amount);
  return ranges.map((range) => {
    const fromDay = processNumberWithZero(range.start.getDate());
    const fromMonth = processNumberWithZero(range.start.getMonth() + 1);
    const toDay = processNumberWithZero(range.end.getDate());
    const toMonth = processNumberWithZero(range.end.getMonth() + 1);
    return `${fromMonth}/${fromDay} - ${toMonth}/${toDay}`;
  })
}