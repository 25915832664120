import { useParams } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import Iconify from '../iconify';

export const ReportHeader = (
  { handleBack, onEdit, onDelete, onSend, isEditing = false, onDownload }
) => {
  const { reportId } = useParams();
  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <div>
        <Button
          variant="outlined"
          startIcon={<Iconify icon={'mdi:chevron-left'} />}
          onClick={handleBack}
          size={'small'}
        >
          Back to reports
        </Button>
      </div>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Button variant={'contained'} color={'success'} onClick={onEdit}>
          {!isEditing && <Iconify icon={'mdi:account-edit'} />}
          {isEditing && <Iconify icon={'mdi:check'} />}
        </Button>
        <Button variant={'contained'} onClick={onSend}>
          <Iconify icon={'mdi:telegram'} />
        </Button>
        <Button
          onClick={onDownload}
          variant={'contained'} sx={[
          {
            '&': { backgroundColor: 'darkorange' },
            '&:hover': { backgroundColor: 'orange' },
          }
        ]}>
          <Iconify icon={'mdi:download'} />
        </Button>
        <Button variant={'contained'} sx={[
          {
            '&': { backgroundColor: 'error.dark' },
            '&:hover': { backgroundColor: 'error.main' },
          }
        ]} onClick={onDelete}>
          <Iconify icon={'mdi:close'} />
        </Button>
      </Stack>
    </Stack>
  );
};