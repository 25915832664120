import {
  makeGetAuthRequest, makePostAuthRequest,
  makePostRequest
} from './common';
import { authService } from '../services';

export const loginRequest = (data) => makePostRequest('/login', data, { method: 'POST' })

export const fetchMe = async () => makeGetAuthRequest('/me');

export const logout = async () => {
  await makePostAuthRequest('/logout');
  authService.clear();
};

export const forgotPassword = (data) => makePostRequest('/forgot', data);
export const resetPassword = (data) => makePostRequest(`/reset`, data);
