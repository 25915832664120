const titleToName = (value) => value.toLowerCase().replaceAll(' ', '_');

export const createColumnItem = ({
    title = '',
    value, width = 'auto',
    headerAlign = null,
    valueAlign = null,
    sortable = false,
    sortName = null,
  }) => ({
  title,
  value,
  width,
  headerAlign,
  valueAlign,
  sortable,
  sortName: sortName || titleToName(title),
});

export const defaultSorting = (field, direction = 'asc') => ({ field, direction });

export const defaultTableFilter = (
  {
    title,
    name,
    value = '',
    type = 'search',
    values = []
  }
) => ({
  title,
  name: name || titleToName(title),
  value,
  type,
  values,
});
