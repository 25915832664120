import {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { AppLayout } from '../../layouts/app';
import { processSquareOauthCallback } from '../../requests/square';
import { useNotifications } from '../../providers';
import { INTEGRATIONS_ROUTE } from '../../router';

const statusMap = {
  info: {
    text: 'Loading...',
    color: '#000000'
  },
  success: {
    text: 'Successfully connected with Square! You will be redirected in 3 sec...',
    color: '#128600'
  },
  error: {
    text: 'Something went wrong during connecting with Square! Try again later!',
    color: '#af0051'
  }
};

export const SquareOauthCallbackPage = () => {

  const [searchParams] = useSearchParams();
  const { addNotification } = useNotifications();
  const [status, setStatus] = useState(statusMap.info);

  useEffect(() => {
    processSquareOauthCallback({
      code: searchParams.get('code')
    }).then(() => {
      addNotification({
        type: 'success',
        message: 'Successfully connected with Square! You will be redirected in 3 sec...'
      });
      setStatus({ ...statusMap.success });
      setTimeout(() => {
        window.location.href = INTEGRATIONS_ROUTE;
      }, 3000);
    }).catch((e) => {
      setStatus(statusMap.error);
      addNotification({
        message: e.message || 'Something went wrong, please try again later!',
        type: 'error',
      });
      setTimeout(() => {
        window.location.href = INTEGRATIONS_ROUTE;
      }, 3000);
    })
  }, []);

  return (
    <AppLayout>
      <Typography
          sx={{ color: status.color }}
          variant={'h4'}
      >
        {status.text}
      </Typography>
    </AppLayout>
  );
}