import {useEffect, useRef, useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { loginRequest } from '../../../requests/auth';
import { DASHBOARD_ROUTE, FORGOT_PASSWORD_ROUTE } from '../../../router';
import { authService } from '../../../services';
import { useNotifications } from '../../../providers';

export default function LoginForm() {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const emailRef = useRef(null);
  const passRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const enterHandler = async (e) => {
      if (e.key !== 'Enter') return;
      await handleClick();
    }
    window.addEventListener('keypress', enterHandler);

    return () => {
      window.removeEventListener('keypress', enterHandler);
    };
  }, []);

  const handleClick = async () => {
    try {
      setLoading(true);
      const response = await loginRequest({
        password: passRef.current.value,
        email: emailRef.current.value,
      });
      authService.saveAccessToken(response.access_token, response.expires_in)
      setLoading(false);
      navigate(DASHBOARD_ROUTE, { replace: true });
    } catch (e) {
      addNotification({
        message: e.error || e.message || 'Something went wrong!',
        type: 'error',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          inputRef={emailRef}
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          inputRef={passRef}
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <NavLink to={FORGOT_PASSWORD_ROUTE}>Forgot password?</NavLink>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </>
  );
}
