import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import PropTypes from 'prop-types';
import Iconify from "../iconify";

export const SearchFilter = ({ label, onChange, onClear, value }) => {
  const clearHandler = () => {
    if (!value.length) return;
    onClear();
  }

  return (
    <FormControl variant="outlined" size={'small'}>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type='text'
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment
            position="end"
            onClick={clearHandler}
            sx={{ cursor: 'pointer' }}
          >
            <Iconify sx={{ opacity: value.length ? '1' : '0' }} icon={'mdi:close'} />
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
}

SearchFilter.propTypes = {
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
}
