import { Stack, Button } from '@mui/material';
import { AppLayout } from '../../layouts/app';
import { pageTitle } from '../../utils/seo';
import { TransactionsTable } from '../../components/transactions';
import Iconify from '../../components/iconify';
import { downloadExport } from '../../requests/transaction';
import { useNotifications } from '../../providers';


export const TransactionsListPage = () => {
  const { addNotification } = useNotifications();

  const onDownloadList = async () => {
    try {
      await downloadExport();
    } catch (e) {
      addNotification({
        type: 'error',
        message: e.message || 'Something went wrong!',
      });
    }
  }

  const headerRight = <Stack direction={'row'} alignItems={'center'} spacing={3}>
    <Button onClick={onDownloadList}>
      <Iconify icon={'mdi:download'} />
    </Button>
  </Stack>;

  return (
    <AppLayout
      title={pageTitle('Transactions')}
      pageHeader={'Transactions'}
      pageHeaderRight={headerRight}
    >
      <TransactionsTable />
    </AppLayout>
  );
}
