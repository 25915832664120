import { Navigate, useRoutes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import { VendorsListPage } from './pages/vendor/VendorsListPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import { AuthGuard } from './guard';
import {
  BILLING_ROUTE,
  DASHBOARD_ROUTE,
  FORGOT_PASSWORD_ROUTE, INTEGRATIONS_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  REPORTS_ROUTE, RESET_PASSWORD_ROUTE, SQUARE_ROUTE, TRANSACTIONS_ROUTE,
  VENDORS_ROUTE
} from './router';
import { NotAuthGuard } from './guard/NotAuthGuard';
import { ReportsPage } from './pages/ReportsPage';
import { ProfilePage } from './pages/ProfilePage';
import { VendorPage } from './pages/vendor/VendorPage';
import { BillingPage } from './pages/BillingPage';
import { ReportPage } from './pages/report/ReportPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import { TransactionsListPage } from './pages/transaction/TransactionsListPage';
import { SquareOauthCallbackPage } from './pages/square/SquareOauthCallbackPage';
import { IntegrationsPage } from './pages/IntegrationsPage';

export default function Router() {
  return useRoutes([
    {
      path: DASHBOARD_ROUTE,
      element: <AuthGuard><DashboardAppPage /></AuthGuard>,
    },
    {
      path: VENDORS_ROUTE,
      element: <AuthGuard><VendorsListPage /></AuthGuard>,
    },
    {
      path: `${VENDORS_ROUTE}/:vendorId`,
      element: <AuthGuard><VendorPage /></AuthGuard>,
    },
    {
      path: REPORTS_ROUTE,
      element: <AuthGuard><ReportsPage /></AuthGuard>,
    },
    {
      path: `${REPORTS_ROUTE}/:reportId`,
      element: <AuthGuard><ReportPage /></AuthGuard>,
    },
    {
      path: `${TRANSACTIONS_ROUTE}`,
      element: <AuthGuard><TransactionsListPage /></AuthGuard>,
    },
    {
      path: PROFILE_ROUTE,
      element: <AuthGuard><ProfilePage /></AuthGuard>,
    },
    {
      path: LOGIN_ROUTE,
      element: <NotAuthGuard><LoginPage /></NotAuthGuard>,
    },
    {
      path: FORGOT_PASSWORD_ROUTE,
      element: <NotAuthGuard><ForgotPasswordPage /></NotAuthGuard>,
    },
    {
      path: RESET_PASSWORD_ROUTE,
      element: <NotAuthGuard><ResetPasswordPage /></NotAuthGuard>,
    },
    {
      path: BILLING_ROUTE,
      element: <AuthGuard><BillingPage /></AuthGuard>,
    },
    {
      path: '/oauth/square/callback',
      element: <AuthGuard><SquareOauthCallbackPage /></AuthGuard>
    },
    {
      path: INTEGRATIONS_ROUTE,
      element: <AuthGuard><IntegrationsPage /></AuthGuard>,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
