import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLayout } from '../layouts/app';
import { pageTitle } from '../utils/seo';
import { ReportsTable } from '../components/report';
import Iconify from '../components/iconify';
import { CreateReportModal } from '../components/report/CreateReportModal';
import { REPORTS_ROUTE } from '../router';

export const ReportsPage = () => {
  const [reportModal, setReportModal] = useState(false);
  const navigate = useNavigate();

  const closeReportModal = (id, reason) => {
    if (reason) {
      setReportModal(false);
      return;
    }
    if (typeof id === 'number' && id) {
      navigate(`${REPORTS_ROUTE}/${id}`);
      return;
    }
    setReportModal(false);
  }

  const openReportModal = () => {
    setReportModal(true);
  }

  const pageActions = <Stack>
    <Button variant={'contained'} color='success' onClick={openReportModal}><Iconify icon={'mdi:plus'} /></Button>
  </Stack>;

  return (
    <AppLayout
      title={pageTitle('Reports')}
      pageHeader={'Reports'}
      pageHeaderRight={pageActions}
    >
      <ReportsTable />
      <CreateReportModal
        isOpen={reportModal}
        onClose={closeReportModal}
      />
    </AppLayout>
  );
}
