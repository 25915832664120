import { Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Header from '../header';
import Nav from '../nav';
import { pageTitle } from '../../utils/seo';
import { Main, StyledRoot } from './styles';

const AppLayout = ({ children, title, pageHeader, pageHeaderRight }) => {
  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      {title && <Helmet>
        <title>{pageTitle(title)}</title>
      </Helmet>}
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main>
        <Container maxWidth="xxl" disableGutters>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mb: 2 }}
          >
            {pageHeader && <Typography variant="h4">{pageHeader}</Typography>}
            {pageHeaderRight && <div>{pageHeaderRight}</div>}
          </Stack>
          <div>
            {children}
          </div>
        </Container>
      </Main>
    </StyledRoot>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  pageHeader: PropTypes.string,
}

export { AppLayout };
