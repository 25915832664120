import { useEffect, useState } from 'react';

export const useDebouncedData = (value, delay = 500) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setData(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    }
  }, [value, delay]);

  return data;
}