import {
  Stack,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, Checkbox, FormControlLabel, TextareaAutosize,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fCurrency } from '../../utils/formatNumber';
import { dateString, formatDate } from '../../utils/formatTime';
import Iconify from '../iconify';
import { useNotifications } from '../../providers';
import { downloadVendorReport } from '../../requests/report';

export const ListItem = ({ title, value }) => <div>{title}: <i>{fCurrency(value)}</i></div>;

const ReportVendorBaseInfo = ({ data, onSend }) => {
  const { reportId } = useParams();
  const vendorId = data.vendor.id;
  const { addNotification } = useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [sendCopy, setSendCopy] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => setIsOpen(false);

  const sendHandler = async () => {
    onSend({ message, cc: sendCopy, vendorId });
    setMessage('');
    setSendCopy(false);
    closeModal();
  }

  const onDownload = async () => {
    try {
      await downloadVendorReport(vendorId, reportId);
    } catch (e) {
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  return (
    <>
      <h4>{data.vendor.name}</h4>
      <div style={{ background: 'lightgray', padding: '1rem', borderRadius: '1rem' }}>
        <ListItem title={'Gross'} value={data.gross} />
        <ListItem title={'Discounts'} value={data.discounts} />
        <ListItem title={'Net'} value={data.net} />
        <ListItem title={'Commission'} value={data.commision} />
        <ListItem title={'Total'} value={data.total} />
        <ListItem title={'Rent'} value={data.rent} />
        <div><b>Payout:</b> {fCurrency(data.payout)}</div>
      </div>
      <Stack direction={'row'} alignItems={'center'} spacing={2} justifyContent={'center'} mt={2}>
        <Button variant={'contained'} onClick={openModal}>
          <Iconify icon={'mdi:telegram'} />
        </Button>
        <Button variant={'contained'} onClick={onDownload}>
          <Iconify icon={'mdi:download'} />
        </Button>
      </Stack>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={isOpen}
        onClose={closeModal}
      >
        <DialogTitle>Send report to vendor</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <TextareaAutosize
                id='message_area'
                minRows={3}
                placeholder={'Message'}
                style={{ resize: 'none', width: '100%', borderRadius: '.5rem', padding: '5px' }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <Grid item md={12}>
              <FormControlLabel
                control={<Checkbox value={sendCopy} onChange={() => setSendCopy(!sendCopy)}/>}
                label={'Send me a copy'}
                labelPlacement={'end'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction={'row'} alignItems={'center'} spacing={3}>
            <Button onClick={sendHandler} color={'success'} variant={'contained'}>Send</Button>
            <Button onClick={closeModal} variant={'contained'}>Close</Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ReportVendorItem = ({ data, onSend }) => {
  return (
    <Accordion
      sx={{
        marginBottom: '1rem',
        '&:before': {
          display: 'none',
        }
      }}
    >
      <AccordionSummary expandIcon={<Iconify icon={'mdi:chevron-down'} />}>
          <h2>{data.vendor.business}</h2>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2}>
          <Grid item md={2}>
            <ReportVendorBaseInfo data={data} onSend={onSend}/>
          </Grid>
          <Grid item md={10}>
            {!data.transactions.length && <div style={{ textAlign: 'center' }}><h3>No transactions</h3></div>}
            {!!data.transactions.length && <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Gross</TableCell>
                  <TableCell>Discounts</TableCell>
                  <TableCell>Net</TableCell>
                  <TableCell>Commission</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell width={'10%'}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!data.transactions.length && data.transactions.map(t => <TableRow key={t.id}>
                  <TableCell>{t.code}</TableCell>
                  <TableCell>{t.quantity}</TableCell>
                  <TableCell>{t.description}</TableCell>
                  <TableCell>{fCurrency(t.gross)}</TableCell>
                  <TableCell>{fCurrency(t.discounts)}</TableCell>
                  <TableCell>{fCurrency(t.net)}</TableCell>
                  <TableCell>{fCurrency(t.commision)}</TableCell>
                  <TableCell>{fCurrency(t.total)}</TableCell>
                  <TableCell>{formatDate(t.processed_at, true)}</TableCell>
                </TableRow>)}
              </TableBody>
            </Table>}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}