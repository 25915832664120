import { useEffect, useState } from "react";
import { createColumnItem, DataTable, defaultSorting, defaultTableFilter } from '../data-table';
import { dateString, formatDate } from '../../utils/formatTime';
import { fetchTransactions } from '../../requests/transaction';
import { fCurrency } from '../../utils/formatNumber';
import { useRequest } from "../../hooks";
import { fetchVendorsDictionary } from "../../requests/vendor";
import { VendorCodes } from '../vendor';

export const TransactionsTable = () => {
  const request = useRequest(() => fetchVendorsDictionary());
  const [filters, setFilters] = useState([
    defaultTableFilter({
      title: 'Description or Code',
      name: 'transaction_query',
      type: 'search',
    })
  ]);

  const processDictionary = (items) => items.map(i => ({
    label: `${i.name} | ${i.business}`,
    value: i.id,
  }));

  useEffect(() => {
    request().then(data => {
      setFilters([
        ...filters,
        defaultTableFilter({
          title: "Vendor",
          name: 'vendor',
          type: 'multi_select',
          values: processDictionary(data),
          value: [],
        }),
      ])
    })
  }, []);

  const tableConfig = [
    createColumnItem({
      title: 'ID',
      value: item => item.id,
      sortable: true,
      sortName: 'id',
    }),
    createColumnItem({
      title: 'CODE',
      value: (item) => <VendorCodes codes={[item.code]}/>,
    }),
    createColumnItem({
      title: 'Quantity',
      value: item => item.quantity,
    }),
    createColumnItem({
      title: 'Description',
      value: item => item.description,
    }),
    createColumnItem({
      title: 'Gross',
      value: item => fCurrency(item.gross),
    }),
    createColumnItem({
      title: 'Discounts',
      value: item => item.discounts,
    }),
    createColumnItem({
      title: 'Net',
      value: item => fCurrency(item.net),
      width: '10%',
      headerAlign: 'center',
      valueAlign: 'center',
    }),
    createColumnItem({
      title: 'Commission',
      value: item => fCurrency(item.commision),
      headerAlign: 'center',
      valueAlign: 'center',
    }),
    createColumnItem({
      title: 'Total',
      value: item => fCurrency(item.total),
      headerAlign: 'center',
      valueAlign: 'center',
    }),
    createColumnItem({
      title: 'Date',
      value: item => formatDate(item.processed_at),
      sortable: true,
      sortName: 'processed_at',
    }),
    createColumnItem({
      title: '',
      value: () => null,
    }),
  ];

  return (
    <DataTable
      key={filters}
      columnsConfig={tableConfig}
      dataCallback={(params) => fetchTransactions(params)}
      defaultSorting={[defaultSorting('processed_at', 'desc')]}
      defaultFilters={filters}
    />
  );
}