import {
  downloadAuthFile,
  makeDeleteAuthRequest,
  makeGetAuthRequest,
  makePostAuthRequest,
  makePutAuthRequest, processQueryParams
} from './common';

export const fetchVendors = (params = {}) => {
  const queryString = processQueryParams(params);
  const path = '/vendor';
  const url = `${path}?${queryString}`;
  return makeGetAuthRequest(url);
};

export const fetchVendorsDictionary = () => makeGetAuthRequest('/vendor/dictionary');

export const fetchVendor = (id) => makeGetAuthRequest(`/vendor/${id}`);

export const deleteVendor = (id) => makeDeleteAuthRequest(`/vendor/${id}`);

export const updateVendor = (id, updateData) => makePutAuthRequest(`/vendor/${id}`, updateData);

export const downloadExport = () => downloadAuthFile('/vendor/download');

export const createVendorTransaction = (vendorId, data) => makePostAuthRequest(`/vendor/${vendorId}/transaction/save`, data);

export const deleteVendorTransaction = (vendorId, transactionId) => makeDeleteAuthRequest(`/vendor/${vendorId}/transaction/${transactionId}`);

export const createVendor = (data) => makePostAuthRequest(`/vendor/create`, data);
