import { authService } from '../services';
import { HttpError } from '../error/HttpError';

const fullUrl = (url) => `${process.env.REACT_APP_API_URL}${url}`;

const getRequestObject = (data = null, options = null) => {
  const headersReq = options?.headers ?? {};
  const method = options?.method ?? 'GET';

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...headersReq,
  };
  let request = {
    headers,
    credentials: 'include',
  };
  if (data && method !== 'GET') {
    request.body = JSON.stringify(data);
  }
  if (options) {
    request = {
      ...options,
      ...request,
    };
  }
  return request;
}

const getUpdAccessToken = (request) => ({
  ...request,
  headers: {
    ...request.headers,
    'Authorization': `Bearer ${authService.getAccessToken()}`
  }
});

const makeRequest = async (url, data = null, options = null) => {
  const request = getRequestObject(data, options);
  let response = await fetch(fullUrl(url), request);
  if (response.status === 401) {
    try {
      await processRefresh();
      if (request?.headers?.Authorization) {
        const req = getUpdAccessToken(request);
        response = await fetch(fullUrl(url), req);
      }
    } catch (e) {
      const err = await response.json();
      const status = response.status;
      throw new HttpError(err.error, status);
    }
  }
  if (!response.ok) {
    const errorData = await response.json();
    throw new HttpError(errorData.error || errorData.message);
  }
  return response.json();
}

export const processRefresh = async () => {
  const response = await makePostRequest('/refresh');
  authService.saveAccessToken(response.access_token, response.expires_in);
}

export const makeAuthRequest = async (url, data, options) => {
  const acTk = authService.getAccessToken();
  const headers = {
    'Authorization': `Bearer ${acTk}`,
  };

  return makeRequest(url, data, {
    headers: {
      ...options?.headers,
      ...headers
    },
    ...options,
  });
}

export const makeGetAuthRequest = async (url, data, options) => makeAuthRequest(url, data, options);

export const makePutAuthRequest = async (url, data, options) => {
  return makeAuthRequest(url, data, {
    ...options,
    method: 'PUT'
  });
}

export const makePostAuthRequest = async (url, data, options) => {
  return makeAuthRequest(url, data, {
    ...options,
    method: 'POST'
  });
}

export const makeDeleteAuthRequest = async (url, options) => {
  return makeAuthRequest(url, null, {
    ...options,
    method: 'DELETE'
  });
}

export const makePostRequest = async (url, data, options) => makeRequest(url, data, {
  ...options,
  method: 'POST'
});

export const downloadAuthFile = async (url) => {
  const response = await fetch(fullUrl(url), {
    headers: {
      'Authorization': `Bearer ${authService.getAccessToken()}`,
    }
  });
  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  const fileAttachmentName = response.headers.get('Content-Disposition')
    ?.replace('attachment; filename=', '')
    ?.replace('"', '');
  const blob = await response.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  anchor.href = objectUrl;
  if (fileAttachmentName) {
    anchor.download = fileAttachmentName;
  }
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
  anchor.remove();
}

export const processQueryParams = (params = {}) => {
  const resultParams = {};
  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'object') {
      resultParams[key] = JSON.stringify(params[key]);
    } else {
      resultParams[key] = params[key];
    }
  });
  const queryString = new URLSearchParams(resultParams).toString();
  return queryString || '';
}