import {
  Checkbox,
  FormControlLabel
} from "@mui/material";
import PropTypes from 'prop-types';

export const BooleanFilter = ({ onChange, filter}) => {
  return (
    <FormControlLabel
      key={filter.title}
      control={
        <Checkbox
          checked={filter.value}
          onChange={onChange}/>
      }
      label={filter.title}
    />
  );
}

BooleanFilter.propTypes = {
  onChange: PropTypes.func,
  filter: PropTypes.object,
}