import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { fCurrency } from '../../utils/formatNumber';
import { dateString } from '../../utils/formatTime';

export const ReportMiscTable = ({ data }) => {
  return (
    <>
      {!!data.length && <Table>
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Gross</TableCell>
            <TableCell>Discounts</TableCell>
            <TableCell>Net</TableCell>
            <TableCell width={'10%'}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!data.length && data.map(t => <TableRow key={t.id}>
            <TableCell>{t.code}</TableCell>
            <TableCell>{t.quantity}</TableCell>
            <TableCell>{t.description}</TableCell>
            <TableCell>{fCurrency(+t.gross)}</TableCell>
            <TableCell>{fCurrency(+t.discounts)}</TableCell>
            <TableCell>{fCurrency(+t.net)}</TableCell>
            <TableCell>{dateString(t.processed_at)}</TableCell>
          </TableRow>)}
        </TableBody>
      </Table>}
      {!data.length && <div style={{ textAlign: 'center' }}>
        <h2>No data</h2>
      </div>}
    </>
  );
}