export const editorStyle = {
  background: '#ffffff',
  borderRadius: '0 0 10px 10px',
  padding: '0 0 0 10px',
  minHeight: '200px'
};

export const toolbarStyle = {
  marginBottom: '0',
  borderRadius: '10px 10px 0 0'
};