import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from 'react-hook-form';
import Iconify from '../iconify';
import { VendorCodes } from './VendorCodes';
import { useNotifications } from '../../providers';
import { createVendor } from '../../requests/vendor';
import { VendorStatusSelect } from './VendorStatusSelect';

const DialogItemHeader = ({ value }) => <Typography variant={'h6'} mb={1}>{value}</Typography>

export const CreateVendorModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [codes, setCodes] = useState([]);
  const { addNotification } = useNotifications();
  const [codeName, setCodeName] = useState('');
  const [status, setStatus] = useState('pending');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();
  const [loading, setLoading] = useState(false);

  const addCode = () => {
    if (!codeName) return;
    if (codes.includes(codeName)) {
      addNotification({
        message: `Code '${codeName}' already added!`,
        type: 'info'
      });
      return;
    }
    setCodes([
      ...codes,
      codeName
    ]);
    setCodeName('');
  }

  const deleteCode = (code) => {
    setCodes(codes.filter(c => c !== code));
  }

  const onCreate = async (data) => {
    const reqData = {
      ...data,
      rate: +data.rate,
      rent: +data.rent,
      email_notification: +data.email_notification,
      codes,
      status,
    }
    try {
      setLoading(true);
      const response = await createVendor(reqData);
      addNotification({
        message: 'Vendor was successfully created!',
        type: 'success',
      });
      navigate(`/vendors/${response.id}`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <h3>Create Vendor</h3>
          <VendorStatusSelect onChange={(event) => setStatus(event.target.value)} value={status} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Name'} />
            <TextField
              size={'small'}
              fullWidth
              error={!!errors?.name?.message}
              helperText={errors?.name?.message}
              {...register('name', { required: 'Name is required!' })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Business'} />
            <TextField
              size={'small'}
              fullWidth
              {...register('business')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Phone'} />
            <TextField
              size={'small'}
              fullWidth
              {...register('phone')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Email'} />
            <TextField
              size={'small'}
              fullWidth
              error={!!errors?.email?.message}
              helperText={errors?.email?.message}
              {...register('email', { required: 'Email is required!' })}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Rent'} />
            <TextField
              size={'small'}
              defaultValue={0}
              fullWidth
              type={'number'}
              {...register('rent')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Rate'} />
            <TextField
              size={'small'}
              defaultValue={0}
              fullWidth
              type={'number'}
              {...register('rate')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Notes'} />
            <TextField
              size={'small'}
              fullWidth
              multiline
              rows={4}
              {...register('notes')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Codes'} />
            <OutlinedInput
              size={'small'}
              onChange={(e) => setCodeName(e.target.value)}
              value={codeName}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={addCode}
                    edge="end"
                  >
                    <Iconify icon={'mdi:plus'} />
                  </IconButton>
                </InputAdornment>
              }
            />
            <div style={{ marginTop: '.5rem' }}>
              {codes ? <VendorCodes
                size={'medium'}
                codes={codes}
                onDelete={deleteCode} />: null}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <DialogItemHeader value={'Notify'} />
            <FormControl variant="outlined" size="small">
              <Controller
                  render={
                    () => <Select defaultValue={1} {...register('email_notification')}>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  }
                  name={'email_notification'}
                  control={control}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '1rem 0'}} />
        <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
          <Stack direction={'row'} spacing={2}>
            <LoadingButton
              loading={loading}
              variant={'contained'}
              onClick={handleSubmit(onCreate)}
            >CREATE</LoadingButton>
            <Button color={'error'} onClick={onClose}>CANCEL</Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}