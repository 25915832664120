import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { lastMonthNamesList, lastWeeksListShort } from '../../utils/formatTime';
import { useLoading, useRequest } from '../../hooks';
import { totalCommissionWeekChart, totalCommissionMonthChart } from '../../requests/admin';
import { useNotifications } from '../../providers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  elements: {
    point: {
      radius: 6
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const TotalCommissionChart = () => {
  const [mode, setMode] = useState('month');
  const requestWeek = useRequest(() => totalCommissionWeekChart());
  const requestMonth = useRequest(() => totalCommissionMonthChart());
  const [points, setPoints] = useState([]);
  const [labels, setLabels] = useState(lastMonthNamesList());
  const { isLoading, loading, notLoading } = useLoading();
  const { addNotification } = useNotifications();

  const fetchData = async () => {
    try {
      loading();
      const response = mode === 'week' ? await requestWeek() : await requestMonth();
      setPoints(response.points);
      setLabels(mode === 'week' ? lastWeeksListShort() : lastMonthNamesList());
      notLoading();
    } catch (e) {
      notLoading();
      addNotification({
        message: e.error || e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, [mode]);

  const data = {
    labels,
    datasets: [
      {
        data: points,
        borderColor: 'rgb(0,0,0)',
        backgroundColor: 'rgba(0,0,0)',
        cubicInterpolationMode: 'monotone',
      },
    ],
  };

  const onChangeMode = (modeValue) => {
    if (modeValue === mode) return;
    setMode(mode === 'week' ? 'month' : 'week');
  }

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ p: 2 }}>
        <Typography variant={'h6'}>Total commission</Typography>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Button
            onClick={() => onChangeMode('week')}
            variant={mode === 'week' ? 'contained' : 'outlined'}
          >Week</Button>
          <Button
            onClick={() => onChangeMode('month')}
            variant={mode === 'month' ? 'contained' : 'outlined'}
          >Month</Button>
        </Stack>
      </Stack>
      {isLoading && <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>}
      {!isLoading && <Line options={options} data={data} />}
    </>
  );
}
