import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import Iconify from '../../../components/iconify';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.node.isRequired,
  sx: PropTypes.object,
  deltaPercent: PropTypes.number,
};

const HeaderWithDelta = ({ value, delta }) => {
  if (delta < 0) {
    return (
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {value}<br/>
        <span style={{ color: "red", fontWeight: 100, textTransform: 'uppercase' }}>-{Math.abs(delta)}% since last week</span>
      </Typography>
    );
  }
  if (delta > 0) {
    return (
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {value}<br/>
        <span style={{ color: "green", fontWeight: 100, textTransform: 'uppercase' }}>+{Math.abs(delta)}% since last week</span>
      </Typography>
    );
  }
  return (
    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      {value}<br/>
      <span style={{ color: "blue", fontWeight: 100, textTransform: 'uppercase' }}>0% since last week</span>
    </Typography>
  );
}

export default function AppWidgetSummary(
  {
    title,
    total,
    icon,
    color = 'primary',
    deltaPercent = null,
    sx,
    ...other
  }
) {
  return (
    <Card
      sx={{
        py: 5,
        textAlign: 'center',
        // color: (theme) => theme.palette[color].main,
        // bgcolor: (theme) => theme.palette[color].lighter
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].main,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].main, 0)} 0%, ${alpha(
              theme.palette[color].main,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </StyledIcon>

      <Typography variant="h3">{total}</Typography>

      <HeaderWithDelta value={title} delta={deltaPercent} />
    </Card>
  );
}
