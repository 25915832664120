import {
  AUTH_ACCESS_TOKEN_KEY,
  AUTH_ACCESS_TOKEN_EXPIRES_KEY
} from "../guard/constants";

export class AuthService {
  saveAccessToken(token, expiresInSeconds) {
    const now = new Date().getTime();
    const expiresAt = expiresInSeconds * 1000 + now;
    localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token);
    localStorage.setItem(AUTH_ACCESS_TOKEN_EXPIRES_KEY, `${expiresAt}`);
  }

  isAccessTokenExpired() {
    const tokenExpiresAt = +localStorage.getItem(AUTH_ACCESS_TOKEN_EXPIRES_KEY);
    return new Date().getTime() > tokenExpiresAt;
  }

  getAccessToken() {
    return localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
  }

  clear() {
    localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
    localStorage.removeItem(AUTH_ACCESS_TOKEN_EXPIRES_KEY);
  }
}