import { createContext, useContext, useState } from "react";
import { userDataMapper } from '../utils/auth';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const updateUser = (data) => {
    setUser(userDataMapper(data));
  }

  const value = { user, setUser: updateUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
