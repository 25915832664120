import { downloadAuthFile, makeGetAuthRequest, makePutAuthRequest, processQueryParams } from './common';

export const fetchTransactionsByVendorId = (vendorId, params) => {
  const notNullParams = {};
  Object.keys(params).forEach(key => {
    if (params[key]) notNullParams[key] = params[key];
  });
  const queryString = processQueryParams(notNullParams);
  const path = `/vendor/${vendorId}/transactions`;
  const url = `${path}?${queryString}`;
  return makeGetAuthRequest(url);
};

export const updateVendorTransaction = (vendorId, data) => {
  const transactionId = data.id;
  return makePutAuthRequest(`/vendor/${vendorId}/transaction/${transactionId}`, data);
}

export const fetchTransactions = (params) => {
  const queryString = processQueryParams(params);
  const path = '/transactions/all';
  const url = `${path}?${queryString}`;
  return makeGetAuthRequest(url);
}

export const downloadExport = () => downloadAuthFile('/transaction/download');
