import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthProvider, NotificationsProvider } from './providers';

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <HelmetProvider>
        <BrowserRouter>
          <NotificationsProvider>
            <AuthProvider>
              <ThemeProvider>
                <ScrollToTop />
                <StyledChart />
                <Router />
              </ThemeProvider>
            </AuthProvider>
          </NotificationsProvider>
        </BrowserRouter>
      </HelmetProvider>
    </LocalizationProvider>
  );
}
