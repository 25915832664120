import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import { useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import { editorStyle, toolbarStyle } from './style';


export const EditorComponent = (
  { onChange, defaultValue = '', disabled = false }
) => {
    let parsedContent;
    const jsonContent = defaultValue ?? '{"blocks": [], "entityMap": {}}';
    try {
      parsedContent = convertFromRaw(JSON.parse(jsonContent));
    } catch(err) {
      parsedContent = ContentState.createFromText(defaultValue);
    }
    
    const [editorState, setEditorState] = useState(
      EditorState.createWithContent(parsedContent)
    );

  const changeHandler = (data) => {
    setEditorState(data);
    const rawContent = convertToRaw(data.getCurrentContent());
    const jsonContent = JSON.stringify(rawContent);
    const htmlContent = draftToHtml(rawContent);
    onChange({ json: jsonContent, html: htmlContent });
  }

  return (
    <Editor
      readOnly={disabled}
      onEditorStateChange={changeHandler}
      editorState={editorState}
      toolbarStyle={toolbarStyle}
      editorStyle={editorStyle}
    />
  );
}