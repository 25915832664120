import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { lastMonthNamesList } from '../../utils/formatTime';
import { useLoading, useRequest } from '../../hooks';
import { totalSalesMonthChart } from '../../requests/admin';
import { useNotifications } from '../../providers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const TotalSalesChart = () => {
  const [points, setPoints] = useState([]);
  const [labels, setLabels] = useState([]);
  const requestMonth = useRequest(() => totalSalesMonthChart());
  const { isLoading, loading, notLoading } = useLoading();
  const { addNotification } = useNotifications();

  const fetchData = async () => {
    try {
      loading();
      const response = await requestMonth();
      setPoints(response.points);
      setLabels(lastMonthNamesList());
      notLoading();
    } catch (e) {
      notLoading();
      addNotification({
        message: e.error || e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const data = {
    labels,
    datasets: [
      {
        data: points,
        backgroundColor: 'rgba(0,0,0,0.71)',
        barThickness: 25,
      },
    ],
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ p: 2 }}>
        <Typography variant={'h6'}>Total sales</Typography>
      </Stack>
      {isLoading && <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>}
      {!isLoading && <Bar options={options} data={data} />}
    </>
  );
}
