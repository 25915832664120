import { useNavigate } from 'react-router-dom';
import { HttpError } from '../error/HttpError';
import { authService } from '../services';
import { LOGIN_ROUTE } from '../router';

export const useRequest = (callback) => {
  const navigate = useNavigate();

  return (...args) => new Promise((resolve, reject) => {
    callback(...args)
      .then(data => resolve(data.data))
      .catch(error => {
        if (error instanceof HttpError) {
          if (error.status === 401) {
            authService.clear();
            navigate(LOGIN_ROUTE);
            return;
          }
        }
        reject(error);
      })
  });
}