import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TableSortLabel, TextField
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DatePicker, DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import Iconify from '../iconify';
import {useDebouncedData, useRequest} from '../../hooks';
import { fetchTransactionsByVendorId, updateVendorTransaction } from '../../requests/transaction';
import { commonDate } from '../../utils/formatTime';
import { useNotifications } from '../../providers';
import { createVendorTransaction, deleteVendorTransaction } from '../../requests/vendor';
import { fCurrency } from '../../utils/formatNumber';
import { VendorCodes } from './VendorCodes';

export const VendorTransactionsTable = ({ dateFilter = null }) => {
  const { addNotification } = useNotifications();
  const { vendorId } = useParams();
  const request = useRequest((id, filters = {}) => fetchTransactionsByVendorId(id, filters));
  const [transactions, setTransactions] = useState([]);
  const page = useRef(1);
  const [perPage, setPerPage] = useState(10);
  const [loadMore, setLoadMore] = useState(true);
  const [editItemsIds, setEditItemsIds] = useState([]);
  const [editingData, setEditingData] = useState({});
  const [creatingData, setCreatingData] = useState({});
  const [createdAtSort, setCreatedAtSort] = useState('desc');
  const createdAtDebouncedSort = useDebouncedData(createdAtSort);
  const debouncedDateFilter = useDebouncedData(dateFilter);

  const isCreating = () => !!Object.keys(creatingData).length;

  const fetchData = async () => {
    request(vendorId, {
      date: dateFilter,
      page: page.current,
      per_page: perPage,
      sort: [{ direction: createdAtSort, field: 'processed_at' }]
    })
      .then(data => {
        if (page.current === 1) {
          setTransactions(data);
        } else {
          setTransactions([
            ...transactions,
            ...data
          ]);
        }
        setLoadMore(data.length === perPage);
      });
  }

  const handleEditingDataField = (value, id, field) => {
    const data = { ...editingData };
    data[id][field] = value;
    setEditingData(data);
  }

  const onCloseEditTransaction = (t) => {
    const id = t.id;
    if (editItemsIds.includes(id)) {
      setEditItemsIds(editItemsIds.filter(i => i !== id));
      const editData = { ...editingData };
      delete editData[id];
      setEditingData(editData);
    }
  }

  const onEditTransaction = (t) => {
    const id = t.id;
    if (!editItemsIds.includes(id)) {
      setEditItemsIds([...editItemsIds, id]);
      const editData = {
        ...editingData,
        [id]: {
          code: t.code,
          description: t.description
        }
      };
      setEditingData(editData);
    }
  }

  const handleCreatingDataField = (field, value) => {
    const data = { ...creatingData };
    data[field] = value;
    setCreatingData(data);
  }

  useEffect(() => {
    page.current = 1;
    fetchData();
  }, [debouncedDateFilter, createdAtDebouncedSort]);

  const onAddTransaction = () => {
    setCreatingData({
      code: '',
      description: '',
      quantity: 1,
      gross: 0,
      discounts: 0,
      net: 0,
    });
  }

  const updateTransactionArray = (t) => {
    const id = t.id;
    const idx = transactions.findIndex(t => t.id === id);

    if (String(t.vendor_id) !== vendorId) {
      transactions.splice(idx, 1);
    } else {
      transactions[idx] = {
        ...transactions[idx],
        ...t,
      };
    }
  }

  const onUpdateTransaction = async (t) => {
    try {
      const id = t.id;
      const data = editingData[id];
      const dataToUpdate = { id, code: data.code, description: data.description };
      if (data.code === t.code && data.description === t.description) {
        addNotification({
          message: 'Nothing to update!',
          type: 'info'
        });
        return;
      }
      const response = await updateVendorTransaction(vendorId, dataToUpdate);
      addNotification({
        message: 'Transaction successfully updated!',
        type: 'success'
      });
      updateTransactionArray(response.transaction);
      onCloseEditTransaction(t);
    } catch (e) {
      addNotification({
        message: e.error || 'Something went wrong!',
        type: 'error'
      });
    }
  }

  const onLoadMore = async () => {
    page.current += 1;
    await fetchData();
  }

  const onCancelCreation = () => {
    setCreatingData({});
  }

  const onCreateTransaction = async () => {
    try {
      const response = await createVendorTransaction(vendorId, {
        ...creatingData,
        processed_at: creatingData.processed_at ? creatingData.processed_at : new Date().toISOString(),
      });
      setTransactions([
        { ...response.data },
        ...transactions,
      ]);
      onCancelCreation();
      addNotification({
        message: 'Transaction created!',
        type: 'success',
      });
    } catch (e) {
      addNotification({
        message: e.error || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  const onDeleteTransaction = async (t) => {
    if (!window.confirm('Are you sure you want to delete that transaction!')) return;
    try {
      await deleteVendorTransaction(vendorId, t.id);
      const newItems = transactions.filter(i => i.id !== t.id);
      setTransactions(newItems);
      addNotification({
        message: 'Transaction was deleted!',
        type: 'warning',
      });
    } catch (e) {
      addNotification({
        message: e.error || 'Something went wrong!',
        type: 'warning',
      });
    }
  }

  const onCreatedAtSortClick = () => {
    setCreatedAtSort(createdAtSort === 'desc' ? 'asc' : 'desc');
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Gross</TableCell>
              <TableCell align={'center'}>Discounts</TableCell>
              <TableCell align={'center'} width={'10%'}>Net</TableCell>
              <TableCell>Commission</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>
                <TableSortLabel
                    active
                    direction={createdAtSort}
                    onClick={onCreatedAtSortClick}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell align={'center'}>
                {!isCreating() && <IconButton onClick={onAddTransaction}>
                  <Iconify icon={'mdi:plus'} color={'white'} sx={{ background: 'green' }} />
                </IconButton>}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isCreating() && <TableRow>
              <TableCell />
              <TableCell width={'15%'}>
                <TextField
                  size={'small'}
                  fullWidth
                  defaultValue={creatingData.code}
                  onChange={(e) => handleCreatingDataField('code', e.target.value)}
                />
              </TableCell>
              <TableCell width={'5%'}>{creatingData.quantity}</TableCell>
              <TableCell>
                <TextField
                  size={'small'}
                  fullWidth
                  defaultValue={creatingData.description}
                  onChange={(e) => handleCreatingDataField('description', e.target.value)}
                />
              </TableCell>
              <TableCell>{creatingData.net}</TableCell>
              <TableCell>{creatingData.discounts}</TableCell>
              <TableCell>
                <TextField
                  size={'small'}
                  type={'number'}
                  defaultValue={creatingData.net}
                  onChange={(e) => handleCreatingDataField('net', e.target.value)}
                />
              </TableCell>
              <TableCell>{creatingData.commision}</TableCell>
              <TableCell>{creatingData.net}</TableCell>
              <TableCell>
                <MobileDateTimePicker
                  defaultValue={new Date()}
                  label={'Processed At'}
                  size={'small'}
                  onChange={(value) => handleCreatingDataField('processed_at', new Date(value).toISOString())}
                />
              </TableCell>
              <TableCell>
                <Stack direction={'row'}>
                  <IconButton onClick={onCreateTransaction}>
                    <Iconify icon={'mdi:check'} color={'green'} />
                  </IconButton>
                  <IconButton onClick={onCancelCreation}>
                    <Iconify icon={'mdi:close'} color={'red'} />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>}
            {!!transactions.length && transactions.map(t => {
              return <TableRow key={t.id}>
                <TableCell>{t.id}</TableCell>
                <TableCell>
                  {!editItemsIds.includes(t.id) && <VendorCodes codes={[t.code]}/>}
                  {editItemsIds.includes(t.id) &&
                    <TextField
                      size={'small'}
                      fullWidth
                      defaultValue={editingData[t.id].code}
                      onChange={(e) => handleEditingDataField(e.target.value, t.id, 'code')}
                    />
                  }
                </TableCell>
                <TableCell>{t.quantity}</TableCell>
                <TableCell>
                  {!editItemsIds.includes(t.id) && <span>{t.description}</span>}
                  {editItemsIds.includes(t.id) &&
                    <TextField
                      size={'small'}
                      fullWidth
                      defaultValue={editingData[t.id].description}
                      onChange={(e) => handleEditingDataField(e.target.value, t.id, 'description')}
                    />
                  }
                </TableCell>
                <TableCell>{fCurrency(t.gross)}</TableCell>
                <TableCell align={'center'}>{fCurrency(t.discounts)}</TableCell>
                <TableCell align={'center'}>{fCurrency(t.net)}</TableCell>
                <TableCell>{fCurrency(t.commision)}</TableCell>
                <TableCell>{fCurrency(t.net - t.commision)}</TableCell>
                <TableCell>{commonDate(t.processed_at)}</TableCell>
                <TableCell>
                  <Stack direction={'row'} justifyContent={'center'}>
                    {!editItemsIds.includes(t.id) && <IconButton onClick={() => onEditTransaction(t)}>
                      <Iconify icon={'material-symbols:edit-square-outline'} color={'blue'} />
                    </IconButton>}
                    {editItemsIds.includes(t.id) && <IconButton onClick={() => onUpdateTransaction(t)}>
                      <Iconify icon={'mdi:check'} color={'green'} />
                    </IconButton>}
                    {editItemsIds.includes(t.id) && <IconButton onClick={() => onCloseEditTransaction(t)}>
                      <Iconify icon={'mdi:close'} color={'red'} />
                    </IconButton>}
                    {!editItemsIds.includes(t.id) && !!t.custom && <IconButton onClick={() => onDeleteTransaction(t)}>
                      <Iconify icon={'mdi:close'} color={'red'} />
                    </IconButton>}
                  </Stack>
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={'row'} justifyContent={'center'} sx={{ marginTop: '10px' }}>
        {loadMore && <Button variant={'contained'} onClick={onLoadMore}>
          LOAD MORE
        </Button>}
      </Stack>
    </div>
  );
}