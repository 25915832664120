import
{
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  FormControlLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useNotifications } from '../../providers';
import { generateReport } from '../../requests/report';
import { useLoading } from '../../hooks';

export const CreateReportModal = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const { addNotification } = useNotifications();
  const { isLoading, loading, notLoading } = useLoading();

  useEffect(() => {
    reset();
    return () => {
      reset();
    }
  }, []);

  const onGenerate = async (data) => {
    try {
      const start = new Date(data.start);
      const end = new Date(data.end);
      if (start > end) {
        notLoading();
        addNotification({
          message: 'End date must be greater than start date!',
          type: 'error'
        });
        return;
      }
      const response = await generateReport({
        ...data,
        end: end.toISOString(),
        start: start.toISOString(),
      });
      const reportId = response.data.id;
      addNotification({
        message: 'Report was successfully created!',
        type: 'success'
      });
      notLoading();
      onClose(reportId);
    } catch (e) {
      notLoading();
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Generate report</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item md={12}>
            <div><b>From:</b></div>
            <DatePicker
              size={'small'}
              slotProps={{
                actionBar: { actions: ['clear'] },
                textField: { fullWidth: true, error: !!errors?.start?.message, helperText: errors?.start?.message},
              }}
              {...register('start', { required: 'Start date is required!' })}
              onChange={(value) => {
                setValue('start', value, { shouldValidate: true });
              }}
            />
          </Grid>
          <Grid item md={12}>
            <div><b>To:</b></div>
            <DatePicker
              size={'small'}
              slotProps={{
                actionBar: { actions: ['clear'] },
                textField: { fullWidth: true, error: !!errors?.end?.message, helperText: errors?.end?.message },
              }}
              {...register('end', { required: 'End date is required!' })}
              onChange={(value) => {
                setValue('end', value, { shouldValidate: true });
              }}
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={<Checkbox {...register('rent')} />}
              label={'Include rent?'}
              labelPlacement={'end'}
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={<Checkbox {...register('fees')} />}
              label={'Include fee?'}
              labelPlacement={'end'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <Stack direction='row' justifyContent={'center'} py={2}>
        <LoadingButton
          variant='contained'
          color={'success'}
          onClick={handleSubmit(onGenerate)}
          loading={isLoading}
        >
          GENERATE
        </LoadingButton>
      </Stack>
    </Dialog>
  );
}