import { createContext, useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { randomId } from '../utils/other';

const NotificationsContext = createContext(null);

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = ({ message, type = 'info' }) => {
    const notification = { id: randomId(), message, type };
    setNotifications([
      ...notifications,
      notification
    ]);
  }

  const notificationIsOpen = (id) => notifications.findIndex(n => n.id === id) !== -1;

  const onClose = (id) => {
    setNotifications(notifications.filter(n => n === id));
  }

  const value = { notifications, addNotification };

  return <NotificationsContext.Provider value={value}>
    {!!notifications.length && notifications.map(n => <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      key={n.id}
      open={notificationIsOpen(n.id)}
      autoHideDuration={3000}
      onClose={() => onClose(n.id)}
    >
      <Alert severity={n.type} sx={{ width: '100%' }}>
        {n.message}
      </Alert>
    </Snackbar>)}
    {children}
  </NotificationsContext.Provider>
}

export const useNotifications = () => useContext(NotificationsContext);
