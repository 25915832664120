import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import StripeCheckout from 'react-stripe-checkout';
import { AppLayout } from '../layouts/app';
import {useLoading, useRequest} from '../hooks';
import { fetchBilling, updateBilling } from '../requests/billing';
import { useNotifications } from '../providers';
import { fCurrency } from '../utils/formatNumber';
import { formatDate } from '../utils/formatTime';

const BillingTitle = ({ title, value }) => <div style={{ fontSize: '1.25rem' }}><b>{title}:</b> {value}</div>

export const BillingCard = ({ children, type, title }) => {
  const typeMap = {
    warning: {
      bg: '#f9ea9b',
      title: '#8a6d3b',
      border: '#f7c55f',
    },
    success: {
      bg: '#c4f3b1',
      title: '#3c763d',
      border: '#a7ed6c',
    },
    info: {
      bg: '#d9edf7',
      title: '#31708f',
      border: '#bce8f1',
    },
  };
  const colors = typeMap[type] ?? typeMap.info;

  return (
    <Paper elevation={4} sx={{ border: `1px solid ${colors.border}` }}>
      <div style={{ backgroundColor: colors.bg, color: colors.title, padding: '10px 15px'}}>{title}</div>
      <div style={{ padding: '10px 15px' }}>
        {children}
      </div>
    </Paper>
  );
}

export const BillingPage = () => {
  const { isLoading, loading, notLoading } = useLoading();
  const request = useRequest(() => fetchBilling());
  const [data, setData] = useState(null);
  const { addNotification } = useNotifications();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [subData, setSubData] = useState({
    value: 'No',
    planAmount: 0,
  });
  
  const fetchData = async () => {
    try {
      loading();
      const response = await request();
      setData({ ...response });
      setSubData({
        value: response?.subscriber ? `Enabled` : 'Disabled',
        planAmount: response?.billing?.subscriptions?.data[0]?.items?.data[0]?.plan?.amount,
      });
      notLoading();
    } catch (e) {
      loading();
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onToken = async (data) => {
    try {
      loading();
      await updateBilling({
        token: data.id,
      });
      addNotification({
        message: 'Payment details successfully updated!',
        type: 'success',
      });
      notLoading();
      await fetchData();
    } catch (e) {
      notLoading();
      addNotification({
        message: e.message || 'Something went wrong!',
        type: 'error',
      });
    }
  }

  const subscriptionData = () => ({
    value: data?.subscriber ? `Yes (${data?.billing?.metadata?.subscription})` : 'No',
    planAmount: data?.billing?.subscriptions?.data[0]?.items?.data[0]?.plan?.amount,
  });

  const total = () => {
    const sub = data?.billing?.subscriptions?.data[0]?.items?.data[0]?.plan?.amount / 100;
    const vendors = data?.vendor_count ? data.vendor_count : 0;
    let total = vendors;
    if (!Number.isNaN(sub)) {
      total += sub;
    }
    return fCurrency(total);
  }

  return (
    <AppLayout title={'Billing'} pageHeader={'Billing'}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <BillingCard title={'Payment Method'} type={'success'}>
            {isLoading && <Stack direction={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>}
            {!isLoading && <>
              <BillingTitle title={'E-mail'} value={data?.billing?.email || 'N/A'} />
              <BillingTitle
                title={'Card Number'}
                value={data?.billing?.sources?.data[0]?.last4 ? `**** **** **** ${data?.billing?.sources?.data[0]?.last4}` : 'N/A'}
              />
              <Stack direction={'row'} justifyContent={'end'}>
                <StripeCheckout
                  name={'Share Front'}
                  description={'Update payment details'}
                  image={'/assets/logo.png'}
                  email={data?.billing?.email}
                  token={onToken}
                  opened={() => setUpdateLoading(true)}
                  closed={() => setUpdateLoading(false)}
                  stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                >
                  <Button variant={'outlined'}>
                    {updateLoading && <CircularProgress size={24} color={'primary'} />}
                    {!updateLoading && <span>UPDATE</span>}
                  </Button>
                </StripeCheckout>
              </Stack>
            </>}
          </BillingCard>
        </Grid>
        <Grid item md={6}>
          <BillingCard title={'Subscription'}>
            {isLoading && <Stack direction={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>}
            {!isLoading && <>
              <BillingTitle title={'Vendors'} value={+data?.vendor_count} />
              <BillingTitle
                title={'Subscription'}
                value={subData.value}
              />
            </>}
          </BillingCard>
        </Grid>
        <Grid item md={6}>
          <BillingCard title={
            `Next Payment - ( ${data?.next_payment_due ? formatDate(data?.next_payment_due) : 'N/A'} )`
          } type={'warning'}>
            {isLoading && <Stack direction={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>}
            {!isLoading && <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell align={'center'}>Quantity</TableCell>
                  <TableCell align={'center'}>Subtotal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Subscription</TableCell>
                  <TableCell align={'center'}>1</TableCell>
                  <TableCell align={'center'}>
                    {subscriptionData().planAmount ? fCurrency(subscriptionData().planAmount / 100) : 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vendors</TableCell>
                  <TableCell align={'center'}>{Number(data?.vendor_count ?? 0)}</TableCell>
                  <TableCell align={'center'}>{data?.vendor_count ? fCurrency(Number(data?.vendor_count * 3 ?? 0)) : 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell align={'center'}>Total</TableCell>
                  <TableCell align={'center'}>
                    <strong>{total()}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>}
          </BillingCard>
        </Grid>
      </Grid>
    </AppLayout>
  )
}