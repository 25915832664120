import SvgColor from '../../components/svg-color';
import Iconify from '../../components/iconify';
import { INTEGRATIONS_ROUTE, REPORTS_ROUTE, TRANSACTIONS_ROUTE, VENDORS_ROUTE } from '../../router';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'vendors',
    path: VENDORS_ROUTE,
    icon: icon('ic_user'),
  },
  {
    title: 'Transactions',
    path: TRANSACTIONS_ROUTE,
    icon: <Iconify icon={'mdi:barcode'} />,
  },
  {
    title: 'reports',
    path: REPORTS_ROUTE,
    icon: <Iconify icon={'mdi:chart-bar'} />,
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: <Iconify icon={'mdi:cash-usd'} />,
  },
  {
    title: 'Integrations',
    path: INTEGRATIONS_ROUTE,
    icon: <Iconify icon={'mdi:square-inc'}/>
  }
];

export default navConfig;
