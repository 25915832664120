import {
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchReports } from '../../requests/report';
import { formatDate } from '../../utils/formatTime';
import Iconify from '../iconify';
import { REPORTS_ROUTE } from '../../router';
import { createColumnItem, DataTable, defaultSorting } from '../data-table';

export const ReportsTable = () => {
  const navigate = useNavigate();

  const goToReport = (id) => {
    navigate(`${REPORTS_ROUTE}/${id}`);
  }

  const tableConfig = [
    createColumnItem({
      title: 'ID',
      value: item => item.id,
      headerAlign: 'center',
      valueAlign: 'center',
      sortable: true,
    }),
    createColumnItem({
      title: 'Start Date',
      value: item => formatDate(item.start_date),
      sortable: true,
    }),
    createColumnItem({
      title: 'End Date',
      value: item => formatDate(item.end_date),
      sortable: true,
    }),
    createColumnItem({
      title: 'Include Rent',
      value: item => item.include_rent ? 'Yes' : 'No',
      headerAlign: 'center',
      valueAlign: 'center',
    }),
    createColumnItem({
      title: 'Include Fees',
      value: item => item.include_fees ? 'Yes' : 'No',
      headerAlign: 'center',
      valueAlign: 'center',
    }),
    createColumnItem({
      title: 'Created Date',
      value: item => formatDate(item.created_at),
    }),
    createColumnItem({
      value: (item) => (
        <IconButton onClick={() => goToReport(item.id)}>
          <Iconify icon={'mdi:eye'} />
        </IconButton>
      ),
    })
  ];

  return (
    <div>
      <DataTable
        columnsConfig={tableConfig}
        dataCallback={(params) => fetchReports(params)}
        defaultSorting={[defaultSorting('id', 'desc')]}
      />
    </div>
  );
}